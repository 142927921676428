/**
 * Created by mamikon on 2/8/17.
 */
var previosCountry = false;
$("[name=country]").on("change", function () {
    additionalFieldGenerator();
    if($(this).val() == 'BY') {
        previosCountry = 'BY';

    } else {
        previosCountry = false;
    }

});

$("[name=type]").on("change", function () {
    additionalFieldGenerator();
});
function additionalFieldGenerator() {
    if ($("[name=country]").val() == 'BY' && previosCountry != 'BY') {
        if ($("[name=type]:checked").val() == 'individual') {
            belarusResidentPerson();

        } else {

            belarusResidentCompany();
        }
    } else {

            if ($("[name=type]:checked").val() == 'individual') {
                nonResidentPerson();
            } else {
                nonResidentCompany();
            }

    }
}


function belarusResidentPerson() {

    var fields = renderField('p_personalnmbr', 'Enter personal identification document number (passport / ID)', 'ID number');
    fields += renderField('p_nmbr', 'Enter passport number', 'Passport number');
    fields += renderField('p_org', 'Organization, which issued the passport','Passport issuer');
    fields += renderField('p_date', 'Passports date of issue','Date of issue');

    $('#additional-fields_by').html(fields);
    tuftaKnopka();
}
function belarusResidentCompany() {

    var fields = renderField('unp', 'Tax ID/VAT number', 'Tax number');
    fields += renderField('erg_number', 'Registration number in EGR', 'EGR number');
    fields += renderField('erg_org', 'Name of authority executed the registration','ERG organisation');
    fields += renderField('erg_date', 'Resolution date of state registration','ERG date');

    $('#additional-fields_by').html(fields);
    tuftaKnopka();
}
function nonResidentCompany() {
    $('#additional-fields_by').html('There are no required fields for Company');
    tuftaKnopka();
}
function nonResidentPerson() {

    var fields = renderField('p_personalnmbr', 'Enter personal identification document number (passport / ID)', 'ID number');
    $('#additional-fields_by').html(fields);
    tuftaKnopka();
}
function tuftaKnopka() {
    $('.configur input').on('keyup change', function () {
        var requestBody = renderRegisterRequest();
        if (validateRequest(requestBody)) {
            $("#register-btn").removeClass('disabled');
        } else {
            $("#register-btn").addClass('disabled');
        }
    });
}
function renderField(name, placeholder, label) {
    var field = '';
    field += '<div class="form-group by_additional">';
    field += '<label for="by-' + name + '" class="col-md-6 required control-label" aria-required="true">' + label + '</label>';
    field += '<div class="col-md-16">';
    field += '<input type="text" data-field="text" class="form-control" id="by-' + name + '" required="required" placeholder="' + placeholder + '" name="_by-' + name + '" aria-required="true"><span class="validation-icon"></span>';
    field += '</div>';
    field += '</div>';
    return field;
}

 var previosCountry1 = false;
    if($("[name=owner_country]").val() == 'BY') {
        previosCountry1 = 'BY';
    } else {
        previosCountry1 = false;
    }

    $("[name=owner_country]").on("change", function () {
        additionalFieldEditGenerator();
        if($(this).val() == 'BY') {
            previosCountry1 = 'BY';

        } else {
            previosCountry1 = false;
        }

    });
    
function additionalFieldEditGenerator() {
    if ($("[name=owner_country]").val() == 'BY') {
        if ($("#owner_entity").val() == 'person') {
            belarusResidentPerson();

        } else {

            belarusResidentCompany();
        }
    } else {

        if ($("#owner_entity").val() == 'person') {
            nonResidentPerson();
        } else {
            nonResidentCompany();
        }

    }
}