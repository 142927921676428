$.validator.setDefaults({
    onkeyup: function (element) {
        clearTimeout(timeoute);
        if (!$(element).valid()) {
            $(element).closest('.form-group').removeClass('has-error');
            timeoute = setTimeout(function () {
                $(element).closest('.form-group').removeClass('has-success');
                $(element).closest('.form-group').addClass('has-error');
            }, 1500)
        } else {
            $(element).next('.help-block').hide();
        }
    },

    highlight: function (element) {
        $(element).closest('.form-group').removeClass('has-success');
        $(element).closest('.form-group').addClass('has-error');

    },
    unhighlight: function (element) {
        $(element).closest('.form-group').removeClass('has-error');
    },
    errorElement: 'span',
    errorClass: 'help-block',
    errorPlacement: function (error, element) {
        if (element.parent('.input-group').length) {
            error.insertAfter(element.parent());
        } else {
            error.insertAfter(element);
        }

    },
    success: function (element) {
        $(element).closest('.form-group').removeClass('has-error');
        $(element).closest('.form-group').addClass('has-success');
    }
});
$(".form-validate").validate();
if ($("[name=is_organisation]:checked").val() == '1') {

    $('.trigger').show();
    $('.entity-hide').removeClass("person");
    $('.entity-hide').removeClass("legal");
    $('.entity-hide').addClass("legal");
    renderRequired();
} else {
    if ($("[name=is_organisation]:checked").val() === '0') {

        $('.trigger').show();
        $('.entity-hide').removeClass("person");
        $('.entity-hide').removeClass("legal");
        $('.entity-hide').addClass("person");
        renderRequired();
    }
}


$("[name=is_organisation]").change(function () {

    $('.trigger').show();
    $('.entity-hide').removeClass("person");
    $('.entity-hide').removeClass("legal");
    if ($(this).val() == '1') {
        $('.entity-hide').addClass("legal");
    } else {
        $('.entity-hide').addClass("person");
    }
    $(".form-validate").validate();
    renderRequired();

});

function renderRequired() {
    $(".form-validate [required]").removeAttr('required');
    $(".required").parent("div").find("input").prop("required", true);
    if ($('.entity-hide').hasClass("person")) {
        $(".legal-wrapper [required]").removeAttr('required');
        $(".person-wrapper").find('.required').parent("div").find("input").prop("required", true);

    }
    if ($('.entity-hide').hasClass("legal")) {
        $(".person-wrapper [required]").removeAttr('required');
        $(".legal-wrapper").find('.required').parent("div").find("input").prop("required", true);
    }

    $(".form-validate").validate();
    $(".form-validate").on('keyup change', function () {
        var elem = $('.form-validate [required]');
        activateButton(elem);
    });

}

$.validator.addMethod("zip", function (value, element) {
    // zip_number = zip_number.replace(/\(|\)|\s+|-/g, "");
    var zip_number = /^[a-zA-Z0-9 _.-]*$/;
    // return this.optional(element) || value.match(zip_number);
    return this.optional(element) || value.length >=4 &&
        value.match(zip_number);
}, "Please specify a valid zip/post code number");

$.validator.addMethod("tel", function (phone_number, element) {
    phone_number = phone_number.replace(/\(|\)|\s+|-/g, "");
    return this.optional(element) || phone_number.length > 9 &&
        phone_number.match(/^(\+|00)?(?:[0-9] ?){10,14}[0-9]$/);
}, "Please specify a valid phone number with your area code");
$.validator.addMethod( //override email with django email validator regex - fringe cases: "user@admin.state.in..us" or "name@website.a"
    'email',
    function (value, element) {
        return this.optional(element) || /(^[-!#$%&'*+/=?^_`{}|~0-9A-Z]+(\.[-!#$%&'*+/=?^_`{}|~0-9A-Z]+)*|^"([\001-\010\013\014\016-\037!#-\[\]-\177]|\\[\001-\011\013\014\016-\177])*")@((?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}\.?|[A-Z0-9-]{2,}\.?)$)|\[(25[0-5]|2[0-4]\d|[0-1]?\d?\d)(\.(25[0-5]|2[0-4]\d|[0-1]?\d?\d)){3}\]$/i.test(value);
    },
    'Verify you have a valid email address.'
);
$.validator.addMethod('ip', function (value, element) {
    var ip = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return this.optional(element) || value.match(ip);
}, 'Invalid IP address');
$.validator.addMethod('domain', function (value, element) {
    var ns = /(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63}$)/;
    return this.optional(element) || value.match(ns);
}, 'Please Provide Valid NS server');
var timeoute = false;

$(".form-validate").validate();

$(".form-validate").on('keyup', function () {
    var elem = $('.form-validate [required]');

    activateButton(elem);
});
$(".form-validate input").on('change', function () {
    $(this).valid();
});
setInterval(function () {
    if ($('.form-validate input:-webkit-autofill').val()) {

        if ($('.form-validate input:-webkit-autofill').valid()) {
            var elem = $('.form-validate [required]');
            activateButton(elem);
        }

    }
}, 1000);

function activateButton(elem) {
    var status = true;
    $.each(elem, function (index, item) {
        if ($(this).val() == "") {
            status = false;

        }
    });
    if ($(".required input[name=agreement]").length > 0 && $(".required input[name=agreement]:checked").length < 1) {
        status = false;
    }
    if ($(".legal input[name=sex]:not(.not-validate)").length > 0 && $(".legal input[name=sex]:checked").not('.not-validate').length < 1) {
        status = false;
    }
    if ($(".person input[name=person_sex]:not(.not-validate)").length > 0 && $(".person input[name=person_sex]:checked").not('.not-validate').length < 1) {
        status = false;

    }
    if (status) {

        if ($(".form-validate").validate().checkForm()) {
            $(".form-validate").valid();
            $(".validated-send").removeClass("disabled");
        }
        else {
            $(".validated-send").addClass("disabled");
        }
    } else {
        $(".validated-send").addClass("disabled");
    }
    if ($(".payment-methods-list [type=radio]:checked").length == 0 || $("[name=amount]").val() <= 0) {
        $(".fill-btn").addClass('disabled');
    }
}
$(".form-validate input").after('<span class="validation-icon"></span>');
$(".checkbox label input").after("<span class='replacer'></span>");
$(".radio label input").after("<span class='replacer'></span>");
$(".overwrite-field").click(function () {
    $(this).addClass("hidden");
    $("[name=amount]").removeClass("hidden").attr('autofocus', true);

});
$("#plus-balance").click(function () {
    $("[name=amount]").val(parseFloat($("[name=amount]").val()) + 10);
    $("[name=amount]").addClass("hidden");
    $(".overwrite-field").html('$ ' + $($("[name=amount]")).val()).removeClass("hidden");
    $("#amount-error").hide();
    var elem = $('.form-validate [required]');
    activateButton(elem);
    if ($("[name=amount]").val() < 0) {
        $("[name=amount]").val(0);
        $(".overwrite-field").html('$ ' + $($("[name=amount]")).val()).removeClass("hidden");
    }


});
$("#minus-balance").click(function () {
    $("[name=amount]").val(parseFloat($("[name=amount]").val()) - 10);
    $("[name=amount]").addClass("hidden");
    var elem = $('.form-validate [required]');
    activateButton(elem);
    $(".overwrite-field").html('$ ' + $($("[name=amount]")).val()).removeClass("hidden");
    $("#amount-error").hide();
    if ($("[name=amount]").val() < 0) {
        $("[name=amount]").val(0);
        $(".overwrite-field").html('$ ' + $($("[name=amount]")).val()).removeClass("hidden");
    }


});

$("[name=amount]").change(function () {
    if ($("[name=amount]").val() < 0) {
        $("[name=amount]").val(0);
    }
    $(".overwrite-field").html('$ ' + $(this).val()).removeClass("hidden");
    $(this).addClass("hidden");
    $("#amount-error").hide();
    $("[name=amount]").attr('autofocus', false);

});
$(".payment-methods-list [type=radio]").change(function () {
    $(".green-border").removeClass("green-border");
    $(this).parent("label").addClass("green-border");
    var elem = $('.form-validate [required]');
    activateButton(elem);
});

$.ajaxSetup(
    {
        headers: {
            'X-CSRF-Token': $('input[name="_token"]').val()
        }
    });
$("#payment-form").submit(function () {
    loader($("#payment-response"));
    var method = $("[name=pay-method]:checked").val();
    var amount = $("#amount").val();
    if (amount > 0 && method != "") {
        $("#PayModal").modal("show");
        $.get('/balance/fill/' + amount + '/' + method, function (data) {
            $("#payment-response").html(data);

            bankTransferComplite();
            changePaymentMethod();
            getPayModal();

        }).fail(function () {
            location.assign("/dashboard");
        });
    }
    return false;
});
var loaderHtml = '<div class="loader"><div><span></span><span></span><span></span></div></div>';

function loader(element) {

    element.html('<div class="loader"><div><span></span><span></span><span></span></div></div>');

}

function getDomainList() {
    var domains = $('[name=domains]').val().split('\n');
    var list = [];
    $.each(domains, function (index, item) {
        item = $.trim(item);
        if (item != "") {
            list.push(item);
        }
    });
    return uniques(list);
}
$("#search-btn").click(function (event) {
    event.preventDefault();
    if ($.trim($('[name=domains]').val()) != "") {
        var domains = getDomainList();
        renderDomainResult(domains);
    }
    return false;
});

function renderDomainResult(domains) {
    $("#transfer-result").html('');
    var rendered = '<div class="well">';
    if (domains.length > 1) {
        rendered += '<div class="list-row"><div class="checkbox disabled"><label><input name="check-all" type="checkbox" checked disabled><span class="replacer"></span><strong>Search Results</strong></label></div></div><div data-group="checkbox">';
    } else {
        rendered += '<div class="list-row"><div class="checkbox disabled"><label><strong>Search Result</strong></label></div></div><div data-group="checkbox">';
    }

    $.each(domains, function (index, item) {
        rendered += '<div class="list-row"  data-domain=\'' + clearId(item) + '\'"><div class="row"><div class="checkbox  disabled col-sm-6 col-xs-12 dom-name"><label><div class="text-overflow"><input type="checkbox" disabled><span class="replacer"></span>';
        rendered += '<span class="bg-well">' + escapeHtml(item) + '</span></div><!--end-texoverflow--></label></div><!--end-checkbox-->';
        rendered += '<div class="col-sm-6 col-xs-12  dom-status"></div><div class="col-sm-6 col-xs-12  dom-price"> ' + loaderHtml + ' </div> <div class="col-sm-6 col-xs-12  dom-action"></div>';
        rendered += '</div><!--end-row--></div><!--end-list-row-->';
    });
    rendered += "<div class='btn-list pull-right'><button id='add-to-cart' class='bolder btn btn-primary  disabled'><span class='fa fa-shopping-cart mgr-5'></span> Add Selected to Cart</button>";
    rendered += "<a href='/cart/view' class='btn btn-primary-outline bolder  pull-right'>View Cart</a></div></div>";

    rendered += '</div>';
    $("#search-result").html(rendered);
    getDomainResult(domains);
}

function clearId(item) {
    item = item.toLowerCase();
    item = item.replace(/ /g, "_");
    item = item.replace(/[^a-zA-Z0-9]+/g, "");
    return 'dom' + item.replace(/'/g, "");
}

function getDomainResult(domains) {
    var list = clearDomainList(domains);
    var requestInterval = setInterval(function () {
        if (list.length > 0) {
            var result = ajaxApiCall("/ajax/domain/" + list[0]);
            var tempDom = list[0];
            removeItemByValue(list, list[0]);

            result.always(function (data) {
                var resp = $.parseJSON(data.responseText);
                var rowId = clearId(resp.domain);
                if (result.status == 406) {
                    setInvalidExtension(rowId);
                }else if(resp.code == 408){
                    setNotSupported(rowId,resp.massage);
                } else {
                    var response = $.parseJSON(result.responseText);
                    if (response.available) {
                        if(isPremiumMe(response.domain)) {
                            setAvailablePremiium(rowId, response);
                        } else {
                            setAvailable(rowId, response);
                        }

                        checkAllListenner();
                    } else {
                        setUnavailable(rowId, tempDom, "<pre>" + response.whois + "</pre>");
                    }
                }
            });
        } else {
            clearInterval(requestInterval);
        }
    }, 1000);
}
function checkAllListenner() {
    $('[name=check-all]').on('change', function () {

        if ($(this).is(':checked')) {
            $(this).parents('.well').find('input[type=checkbox]').not(':disabled').prop('checked', true);
            $(".bulk-renew").removeClass("disabled")

        } else {
            $(this).parents('.well').find('input[type=checkbox]').not(':disabled').prop('checked', false);
            $(".bulk-renew").addClass("disabled");
        }

    });
    $("[data-group=checkbox] input[type=checkbox]").on('change', function () {
        var count = $(this).parents('[data-group=checkbox]').find('input[type=checkbox]:checked').not(':disabled').length;
        var countBox = $(this).parents('[data-group=checkbox]').find('input[type=checkbox]').not(':disabled').length;
        if (count == countBox) {
            $(this).parents('.well').find('[name=check-all]').prop('checked', true);
        } else {
            $(this).parents('.well').find('[name=check-all]').prop('checked', false);
        }
        if ($("#my-domains-list tbody input[type=checkbox]:checked").length > 0) {
            $(".bulk-renew").removeClass("disabled")
        } else {
            $(".bulk-renew").addClass("disabled");

        }
        buttonActivation();
    });

}


function setAdded(domain) {
    rowId = clearId(domain);
    var btn = '<div class="btn-list  mrg-0"><button class="btn btn-primary disabled btn-sm mrg-0"><strong>Added</strong></button>';
    btn += '<a href="/cart/view" class="btn btn-primary btn-sm"><strong>View Cart</strong></a></div>';
    $('[data-domain=' + rowId + "] .dom-action").html(btn);
    $('[data-domain=' + rowId + "] .checkbox").addClass("disabled");
    $('[data-domain=' + rowId + "] .checkbox input[type=checkbox]").attr("disabled", "disabled");

}

function setInvalidExtension(rowId) {
    $('[data-domain=' + rowId + "] div .dom-price").html("");
    $('[data-domain=' + rowId + "] .dom-status").html("<span class='label label-danger'> Unsupported Extension</span>");
}
function setNotSupported(rowId,massage) {
    $('[data-domain=' + rowId + "] div .dom-price").html("");
    $('[data-domain=' + rowId + "] .dom-status").html("<span class='label label-danger'>"+massage+"</span>");
}
function setAvailable(rowId, result) {
    $('[data-domain=' + rowId + "] .dom-status").html("<span class='label label-success'>Available</span>");
    var prices = result.prices;
    if (prices.length > 1) {
        <!-- Split button -->
        var btn = '<div class="btn-group">';
        btn += '<button type="button" data-loading-text="Adding..." id="btn-' + rowId + '" data-domain="' + result.domain + '" data-year="' + prices[0].year + '" class="btn btn-sm btn-primary price-sender"><span class="fa fa-shopping-cart mgr-5"></span><strong>Add to Cart</strong></button>';
        btn += '<button type="button" class="btn price-sender btn-primary btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">';
        btn += '<span class="caret"></span>';
        btn += '<span class="sr-only">Toggle Dropdown</span>';
        btn += '</button>';
        btn += '<ul class="dropdown-menu">';
        $.each(prices, function (index, price) {
            btn += '<li><a href="#" data-row="' + rowId + '" class="price-changer" data-year="' + price.year + '" data-price="' + price.price + '">' + price.year + ' Year/s @ $' + price.price + ' USD</a></li>';
        });
        btn += '</ul>';
        btn += '</div>';
    } else {
        var btn = '<button data-loading-text="Adding..." class="btn mrg-5-0 btn-sm pull-right  btn-primary price-sender" id="btn-' + rowId + '" data-domain="' + result.domain + '" data-year="' + prices[0].year + '" btn-sm"><span class="fa fa-shopping-cart mgr-5"></span><strong>Add to Cart</strong></button>';
    }
    $('[data-domain=' + rowId + "] .dom-action").html(btn);
    $('[data-domain=' + rowId + "] .checkbox").removeClass("disabled");
    updatePrice(rowId, prices[0].price);
    $('[data-domain=' + rowId + "] .checkbox input[type=checkbox]").removeAttr("disabled").attr("checked", "checked");
    $('[name=check-all]').removeAttr("disabled").attr("checked", "checked").parent("label").parent(".checkbox").removeClass("disabled");
    $('#add-to-cart').removeClass("disabled");
    $(".price-changer").on('click', function (event) {
        event.preventDefault();
        updatePrice($(this).data('row'), $(this).data('price'), $(this).data('year'));
        $(".open").removeClass("open");
        return false;
    });
    $("#btn-" + rowId).click(function () {
        addToCart($(this));
    });
    $("#add-to-cart").click(function () {
        sendAllSelected();
    });

}
function setAvailablePremiium(rowId, result) {
    var prices = result.prices;
    $('[data-domain=' + rowId + "] .dom-status").html("<span class='label label-success'>Available</span>");
    $('[data-domain=' + rowId + "] .dom-action").html('');
    $('[data-domain=' + rowId + "] .checkbox").removeClass("disabled");
    $('[data-domain=' + rowId + "] .checkbox input[type=checkbox]").removeAttr("disabled").attr("checked", "checked");
    $('[name=check-all]').removeAttr("disabled").attr("checked", "checked").parent("label").parent(".checkbox").removeClass("disabled");
    $('#add-to-cart').removeClass("disabled");
    $('[data-domain=' + rowId + "] .dom-price").html("<span class='price-text'>This domain is premium. Contact support to purchase.</span>");
}
function addToCart(item) {

    var vars = {};
    vars.domain = item.data('domain');
    vars.year = item.data('year');
    item.button().data('loading-text');
    var method = "POST";
    var res = ajaxApiCall("/ajax/cart", vars, method);
    res.always(function () {
        setAdded(vars.domain);
        buttonActivation();
    });

}

function addToCartTransfer(item,code) {
    // window.location.href = '/cart/transferge?domain='+item.data('domain')+'&code='+code;
    var vars = {};
    vars.domain = item.data('domain');
    vars.code = code;
    doModal(loaderHtml, true, 'TransferWithCodeModal');
    var res = ajaxApiCall("/cart/transferge", vars, "GET");
    res.always(function () {
        // return false;
        var html = "";
        var responseText = $.parseJSON(res.responseText);
        if (responseText.status == 200) {
            // var status = "block-success";
            html += '<div class="block-success">';
            html += "<div class='status-icon'></div>";
            html += '<div class="clearfix"></div><div class="col-sm-12" style="margin-top: 30px;"><button type="button" class="btn btn-primary btn-primary-outline btn-block" data-dismiss="modal" aria-label="Close">Go back to transfer</button></div><div class="col-sm-12" style="margin-top: 30px;"><a href="/domains" class="btn btn-primary btn-block" style="padding: 12px 22px;">Manage your Domains</button></div>'

        } else {
            html += '<div class="block-error">';
            html += "<div class='status-icon'></div>";
            html += '<p>' + responseText.content.textMassage + '</p>';
            html += '<div class="clearfix"></div><div class="col-sm-24" style="margin-top: 30px;"><button type="button" class="btn btn-primary btn-primary-outline btn-block" data-dismiss="modal" aria-label="Close">Go back to transfer</button></div>'

        }

        $("#TransferWithCodeModal .modal-body").html(html)
    });
}
function buttonActivation() {
    var count = $('[data-group=checkbox]').find('input[type=checkbox]:checked').not(':disabled').length;
    if (count > 0) {
        $("#add-to-cart").removeClass("disabled");
    } else {
        $("#add-to-cart").addClass("disabled");
    }
}
function sendAllSelected() {
    var vars = {};
    vars.domains = [];
    var items = $('#search-result').find('[data-group=checkbox]').find('input[type=checkbox]:checked').not(':disabled').parents(".list-row").find(".price-sender");
    $.each(items, function (index, elem) {

        if (!isEmpty($(elem).data('domain'))) {
            vars.domains.push({domain: $(elem).data('domain'), year: $(elem).data('year')});
            $(elem).button('loading');

        }

    });

    var method = "POST";
    var res = ajaxApiCall("/ajax/cart", vars, method);
    res.always(function () {
        $.each(vars.domains, function (i, el) {
            setAdded(el.domain);
        });

        buttonActivation();
    });
}
function updateButtonYear(name, year) {
    $("#btn-" + name).attr('data-year', year);
}
function updatePrice(item, price, year) {
    $('[data-domain=' + item + "] .dom-price").html("<span class='price-text'>$" + price + " USD</span>");
    if (year != undefined) {
        updateButtonYear(item, year);
    }
}
function setUnavailable(rowId, domain, whois) {
    $('[data-domain=' + rowId + "] div .dom-price").html("");
    $('[data-domain=' + rowId + "] .dom-status").html("<span class='label label-danger'>Taken</span>");
    var text = isPremiumMe(domain) ? 'This is premium domain. Contact us to order!' : 'Interested in this domain? Contact us!';
    $('[data-domain=' + rowId + "] .dom-price").html("<span class=''>"+text+"</span>");
    $('[data-domain=' + rowId + "] .dom-action").html("<div class='btn-list mrg-0'><a class='btn btn-xs btn-primary-outline' href='http://" + domain + "' target='_blank'><strong>WWW</strong></a> <a class='btn whois-btn  btn-xs btn-primary-outline'><strong>Whois</strong></a></div> ");
    $('[data-domain=' + rowId + "] .dom-action .whois-btn").click(function () {
        doModal(whois, null, "Unavailable");
    });
}
function clearDomainList(domains) {
    var result = [];
    var reqexp = new RegExp(/(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63}$)/);
    $.each(domains, function (index, domain) {
        rowId = clearId(domain);
        if (domain.match(reqexp)) {
            result.push(domain)
        } else {
            $('[data-domain=' + rowId + "] div .dom-price").html("");
            $('[data-domain=' + rowId + "] .dom-status").html("<span class='label label-danger'>Invalid Domain</span>");
        }
    });
    return result;

}
function ajaxApiCall(url, vars, method) {
    if (method == undefined) {
        method = 'GET';
    }
    var ajax = $.ajax({
        url: url,
        data: vars,
        headers: {
            'X-CSRF-Token': $('input[name="_token"]').val()
        },
        dataType: 'jsonp',
        type: method
    });
    return ajax;
}
function removeItemByValue(arr, value) {
    var index = arr.indexOf(value);
    if (index >= 0) {
        arr.splice(index, 1);
    }
    return arr;
}
function uniques(arr) {
    var a = [];
    for (var i = 0, l = arr.length; i < l; i++)
        if (a.indexOf(arr[i]) === -1 && arr[i] !== '')
            a.push(arr[i]);
    return a;
}

function isValidDomain(domain) {
    var symbols = new RegExp(/(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63}$)/);

    return domain.match(symbols);
}
function escapeHtml(unsafe) {
    return unsafe
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
}
function doModal(formContent, z_index, id) {
    var addZ = "";
    var newID = 'dynamicModal';
    if (!isEmpty(z_index)) {
        addZ = "topModal";
    }
    if (!isEmpty(id)) {
        newID = id;
    }

    var html = '<div id="' + newID + '" class="modal ' + addZ + ' fade" tabindex="-1" role="dialog" aria-labelledby="confirm-modal" aria-hidden="true">';
    html += '<div class="modal-dialog"><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>';
    html += '<div class="modal-content">';
    html += '<div class="modal-body">';
    html += '<p>';
    html += formContent;
    html += '</p>';
    html += '</div>';  // content
    html += '</div>';  // dialog
    html += '</div>';  // modalWindow
    $('body').append(html);
    $("#" + newID).modal();
    $("#" + newID).modal('show');

    $('#' + newID).on('hidden.bs.modal', function (e) {
        $('#' + newID).remove();
    });

}

function getCurrentCart() {
    var elements = ajaxApiCall("/ajax/cart/current", false);
    renderCurrentCart(elements, "#current-cart");

}

function renderCurrentCart(elements, selector) {

    elements.always(function () {
        var domains = $.parseJSON(elements.responseText);
        var rendered = '<div class="well">';
        if (Object.keys(domains).length > 1) {
            rendered += '<div class="list-row"><div class="checkbox"><label><input name="check-all" type="checkbox" checked><span class="replacer"></span><strong>Just added</strong></label>';
        } else {
            rendered += '<div class="list-row"><div class="checkbox disabled"><label><strong>Just added</strong></label>';

        }

        rendered += "<div class='btn-list pull-right' style='margin:-15px 0!important'><a href='#delete-selected' id='delete-selected' class='btn btn-sm btn-primary-outline bolder'>Delete Selected</a>";
        rendered += "<a href='/ajax/cart/flush' class='btn btn-sm btn-primary-outline bolder'>Empty Cart</a></div></div></div><div data-group='checkbox'>";

        if (elements.status == 200) {
            $.each(domains, function (index, item) {
                rendered += '<div class="list-row"  data-domain="' + clearId(index) + '"><div class="row"><div class="checkbox col-xs-9 dom-name"><label><div class="text-overflow"><input type="checkbox" data-domain="' + index + '" data-year="' + item.year + '" checked><span class="replacer"></span>';
                rendered += '<span class="bg-well">' + escapeHtml(index) + '</span></div><!--end-texoverflow--></label></div><!--end-checkbox-->';
                rendered += "<div class='col-xs-4 '><div class='form-group dom-per mrg-5-0 form-group-sm'><select  class='form-control reg-year'>";
                $.each(item.prices, function (i, n) {
                    var sel, appendix = "";
                    if (item.year == n.year) {
                        sel = "selected";
                    }

                    if (n.year > 1) {
                        appendix = 's';
                    }
                    rendered += "<option " + sel + " value='" + n.price + "' data-year ='" + n.year + "'>" + n.year + " year" + appendix + "</option>";
                });
                rendered += '</select></div></div><!--form/col-->';
                rendered += '<div class="col-xs-9  dom-price"> </div> ';
                rendered += '<div class="col-xs-1 remove-div"><a href="#"  data-domain ="' + index + '" class="delete-item"><span class="pdng-10-0  text-muted fa-lg fa fa-trash-o"></span></a></div>';

                rendered += '</div><!--end-row--></div><!--end-list-row-->';
            });
        } else {
            rendered += "<p class='text-muted mrg-5-0'>There is no just added domains</p>"
        }
        rendered += '</div><!--end well--><p></p>';
        $(selector).html(rendered);
        $.each($(".list-row"), function () {
            updatePrice($(this).data('domain'), $(this).find(".reg-year").val());
        });

        onPricechange();
        checkAllListenner();
        deleteSelected();
        cart();
        $("[name=hidden]").trigger("change");

        $(".delete-item").on('click', function () {
            if (confirm("Are you sure you want to delete "+$(this).data("domain")+" domain from your cart?")) {
                deleteCartItem($(this).data("domain"));
            }

            return false;
        });
    });
}
function onPricechange() {

    $(".reg-year").on("change", function () {

        var getPrice = $(this).val();
        var item = $(this).parents(".list-row").data("domain");

        $(this).parents(".list-row").find('.dom-name input[type=checkbox]').data('year', $(this).find(":selected").data('year'));
        updatePrice(item, getPrice);
        $("[name=hidden]").trigger("change");
    });


}
function getRecentCart() {
    var elements = ajaxApiCall("/ajax/cart/recent", false);
    renderRecentCart(elements, "#recent-cart");
}

function renderRecentCart(elements, selector) {

    elements.always(function () {
        var domains = $.parseJSON(elements.responseText);

        if (elements.status == 200) {
            var rendered = '<div class="well">';
            if (Object.keys(domains).length > 1) {
                rendered += '<div class="list-row"><div class="checkbox"><label><input name="check-all" type="checkbox"><span class="replacer"></span><strong>Previously added </strong></label></div></div><div data-group="checkbox">';
            } else {
                rendered += '<div class="list-row"><div class="checkbox disabled"><label><strong>Previously added </strong></label></div></div><div data-group="checkbox">';

            }
            $.each(domains, function (index, item) {
                rendered += '<div class="list-row"  data-domain="' + clearId(index) + '"><div class="row"><div class="checkbox col-xs-9 dom-name"><label><div class="text-overflow"><input type="checkbox"  data-domain="' + index + '" data-year="' + item.year + '"   ><span class="replacer"></span>';
                rendered += '<span class="bg-well">' + escapeHtml(index) + '</span></div><!--end-texoverflow--></label></div><!--end-checkbox-->';
                rendered += "<div class='col-xs-4 '><div class='form-group  mrg-5-0 dom-per form-group-sm'><select  class='form-control reg-year'>";
                $.each(item.prices, function (i, n) {
                    var sel, appendix = "";
                    if (item.year == n.year) {
                        sel = "selected";
                    }

                    if (n.year > 1) {
                        appendix = 's';
                    }
                    rendered += "<option " + sel + " data-year ='" + n.year + "' value='" + n.price + "'>" + n.year + " year" + appendix + "</option>";
                });
                rendered += '</select></div></div><!--form/col-->';
                rendered += '<div class="col-xs-5  dom-price"> </div> ';
                rendered += '<div class="col-xs-4"><span class="price-text">' + item['date'] + '</span></div> ';
                rendered += '<div class="col-xs-1"><a href="#" data-domain ="' + index + '" class="delete-item"><span class="pdng-10-0  text-muted fa-lg fa fa-trash-o"></span></a></div>';

                rendered += '</div><!--end-row--></div><!--end-list-row-->';
            });
            rendered += '</div><!--end well--><p></p>';
        }

        $(selector).html(rendered);
        $.each($(".list-row"), function () {
            updatePrice($(this).data('domain'), $(this).find(".reg-year").val());
        });
        onPricechange();
        checkAllListenner();
        deleteSelected();
        cart();

        checkRecentAdded();
        $(".delete-item").on('click', function () {
            if (confirm("Are you sure you want to delete "+$(this).data("domain")+" domain from your cart?")) {
                deleteCartItem($(this).data("domain"));
            }
            return false;
        });
    });
}

function deleteCartItem(domain) {
    var res = ajaxApiCall("/ajax/cart/delete/" + domain, false);
    res.always(function () {
        if (res.responseText == 'refresh') {
            location.reload();
        }
        $(".list-row[data-domain=" + clearId(domain) + "]").find("input[type=checkbox]").trigger('change').attr('checked', false);
        $(".list-row[data-domain=" + clearId(domain) + "]").hide();
    });

}

function deleteSelected() {
    $("#delete-selected").on("click", function () {
        if (confirm("Are you sure?")) {
            var vars = {};
            vars.domains = [];
            $.each($(".dom-name input[type=checkbox]:checked"), function (item, index) {
                vars.domains.push({domain: $(index).data('domain')});
            });
            var res = ajaxApiCall("/ajax/cart/delete", vars, 'POST');
            res.always(function () {
                $.each(vars.domains, function (i, el) {
                    if (res.responseText == 'refresh') {
                        location.reload();
                    }
                    $(".list-row[data-domain=" + clearId(el.domain) + "]").find("input[type=checkbox]").trigger('change').prop('checked', false);
                    $(".list-row[data-domain=" + clearId(el.domain) + "]").hide();

                });

                buttonActivation();
            });
        }
        return false;
    });

}

function checkRecentAdded() {
    $("#recent-cart [data-group=checkbox] input[type=checkbox]").on("change", function () {
        if (!$(this).hasClass("rebooted")) {
            var result = ajaxApiCall("/ajax/domain/" + $(this).data("domain"));
            $(this).addClass("rebooted");
            result.always(function (data) {
                var response = $.parseJSON(result.responseText);
                if (!response.available) {
                    var rowId = clearId(response.domain);

                    $('[data-domain=' + rowId + "] .dom-per").html("<span class='label label-danger'>Taken</span>");
                    $('[data-domain=' + rowId + "] .dom-price").removeClass("col-xs-5").addClass("col-xs-9").html("<div class='btn-list pull-right mrg-0'><a class='btn btn-xs btn-primary-outline' href='http://" + response.domain + "' target='_blank'><strong>WWW</strong></a> <a class='btn whois-btn  btn-xs btn-primary-outline'><strong>Whois</strong></a></div> ").next("div").remove();
                    $('[data-domain=' + rowId + "] .dom-price .whois-btn").click(function () {
                        doModal(response.whois, null, 'whois-modal');
                    });
                    $('[data-domain=' + rowId + "] .replacer").css("visibility", "hidden").prev().prop('checked', false);

                    ajaxApiCall("/ajax/cart/delete/" + response.domain, false);
                }
            });
        }

    });
    cart();
}
function cart() {

    $("input[type=checkbox]").on("change", function () {
        var list = [];

        $.each($('[data-group=checkbox] input[type=checkbox]:checked'), function (index, elem) {
            var price = $(this).parents(".list-row").find("select").val();
            var elem = {domain: $(this).data("domain"), period: $(this).data("year"), price: price};
            list.push(elem);
            renderCart(list);
            configureListener();
        });
        if ($('[data-group=checkbox] input[type=checkbox]:checked').length == 0) {
            var rendered = "<div class='well'>";
            rendered += '<div class="list-row"><strong>Total Amount</strong></div>';
            rendered += '<div class="list-row"><div class="mrg-5-0">Domains selected <p class="pull-right text-right">0</p></div></div>';
            rendered += '<p class="pull-right  mrg-5-0" style="margin-right:-10px!important">US$ 0</p>';
            rendered += '<button class="btn btn-block disabled btn-primary mrg-10-0" data-loader="Please Wait...">Continue to Configure</button>';
            rendered += "</div>";
            $("#cart-zone").html(rendered);
            configureListener();
        }
    });
}

function renderCart(list) {
    var price = 0;
    var count = 0;
    $.each(list, function (index, item) {
        price += +item.price;
        count++;
    });
    var rendered = "<div class='well'>";
    rendered += '<div class="list-row"><strong>Total Amount</strong></div>';
    rendered += '<div class="list-row"><div class="mrg-5-0">Domains selected <p class="pull-right text-right">' + count + '</p></div></div>';
    rendered += '<p class="pull-right total-text mrg-5-0" style="margin-right: -10px!important">US$ ' + price.toFixed(2) + '</p>';
    rendered += '<button class="btn btn-block btn-primary mrg-10-0" data-loader="Please Wait...">Continue to Configure</button>';
    rendered += "</div>";
    $("#cart-zone").html(rendered);

    configureListener();
}
function configureListener() {
    onPricechange();
    $("#cart-zone button").on('click', function () {
        $(this).button('loading');
        addToConfigure();
        return false;
    })
}
$(document).ready(function () {
    calculateTotal();

    confPriceUpdate('.price-select');
    $('body').prepend("<div class='loader-wrapper'><div class='loader-inner'></div></div>");
    $(".loader-inner").animate({
        width: "100%"
    }, {
        duration: 1000, complete: function () {
            $(".loader-wrapper").remove();
        }
    });
    $('input[type=file]').bootstrapFileInput();

});

$("[aria-type=loader]").on('click', function () {
    $(".loader-close").alert("close");
    $(".loader-wrapper").remove();

    $('body').prepend("<div class='loader-wrapper'><div class='loader-inner'></div></div>");
    $(".loader-inner").css("width", 0).animate({
        width: "30%"
    }, {duration: 1000,});

});

$(function () {

    $('[type=date]').datepicker({
        format: 'yyyy-mm-dd',
        autoclose: true,
        startView: "decade"
    });

});
function addToConfigure() {

    var domains = [];
    $.each($('[data-group=checkbox] input[type=checkbox]:checked'), function (item, data) {

        domains.push({domain: $(this).data('domain'), year: $(this).data('year')});
    });
    var vars = {};
    vars.domains = domains;
    var res = ajaxApiCall("/ajax/cart/configure", vars, 'POST');
    res.always(function () {
        if (res.responseText == 'ok') {
            window.location.href = "/cart/configure";
        }
    });
    return domains;
}
function affixWidth() {
    // ensure the affix element maintains it width
    var affix = $('.affixed-element');
    var width = affix.width();
    affix.width(width);
}

$(document).ready(function () {

    affixWidth();
    var timeoute = false;
    $("[type=domain]").on('keyup', function () {
        clearTimeout(timeoute);
        var element = this;
        if (!$(element).valid()) {
            $(element).closest('.form-group').removeClass('has-error');
            timeoute = setTimeout(function () {
                $(element).closest('.form-group').removeClass('has-success');
                $(element).closest('.form-group').addClass('has-error');
            }, 1500)
        } else {
            $(element).next('.help-block').hide();
        }

    });

    var timeoute = false;
    $("[type=zip]").on('keyup', function () {
        clearTimeout(timeoute);
        var element = this;
        if (!$(element).valid()) {
            $(element).closest('.form-group').removeClass('has-error');
            timeoute = setTimeout(function () {
                $(element).closest('.form-group').removeClass('has-success');
                $(element).closest('.form-group').addClass('has-error');
            }, 1500)
        } else {
            $(element).next('.help-block').hide();
        }

    });

});

function bankTransferComplite() {
    $("#mark-paid").on('click', function () {
        doModal(loaderHtml, true, 'confirmModal');
        //$("#confirmModal").next('.modal-backdrop').addClass("removable").css("z-index", '1051');
        var invoice = $('#invoice').data('invoice');
        $.get('/bank/transaction', {invoice: invoice}, function (data) {
            $("#confirmModal .modal-body").html(data);
            $(function () {
                $('input[type=file]').bootstrapFileInput();
                $('[type=date]').datepicker({
                    format: 'yyyy-mm-dd',
                    autoclose: true,
                    startView: "day"
                });

            });
        });
    });

    $('#bankConfirm').next('.modal-backdrop').remove();

    $('#bankConfirm').remove();
    $('.removable').remove();
    $('.removable').remove();
}

function changePaymentMethod() {
    $("#method").on('change', function () {
        var data = {payment_method: $(this).val(), invoice_id: $("#invoice").data('invoice')};
        res = ajaxApiCall("/ajax/invoice/method", data, "PUT");
        res.always(function () {
            var invoice = getInvoice($("#invoice").data('invoice'));
            loader($("#PayModal .modal-body"));
            invoice.always(
                function () {
                    $("#PayModal .modal-body").html(invoice.responseText);
                    changePaymentMethod();
                    bankTransferComplite();
                    getPayModal();
                }
            );
        });
    });

}


function getInvoice(id) {
    var data = null;
    return ajaxApiCall("/ajax/invoice/" + id, data, "GET");
}
function getPayModal() {
    bankTransferComplite();
    stripeModal();
    paypalModal();
    alipayModal();
    balanceModal();
}
function stripeModal() {
    getMethod('stripe');
}
function balanceModal() {
    getMethod('balance');
}
function alipayModal() {
    getMethod('alipay');
    getMethod('unionpay');
    getMethod('tenpay');
}
function paypalModal() {
    getMethod('paypal');
}

function getMethod(method) {
    $("[data-type=" + method + "-btn]").on('click', function () {
        $(this).button('loading');
        var invoiceId = $('#invoice').data('invoice');
        if (!isEmpty(invoiceId)) {
            var blt = $.get('/payment/' + method + '/' + invoiceId, function (data) {
                if(method != 'balance'){
                    $('body').append(data);

                }
            });

            if(method == 'balance'){

                statusModal(blt);
            }
        }
    })
}
$('.whole-price').on('click', function () {
    doModal(loaderHtml);
    var slab = $(this).data('slab');
    $.get('/ajax/whole-price/' + slab, function (data) {
        $("#dynamicModal .modal-dialog").addClass('modal-lg');
        $("#dynamicModal .modal-body").html(data);
        tabChange();
    });

});

function tabChange() {
    $('.chooser').on('mouseover', function () {
        var slab = $(this).data('slab');
        $('.modal-price .active').removeClass('active');
        $('.' + slab).addClass('active');
        $('.' + slab + '-tariff').addClass('active');
    });
}
$('.tariff-continue').on('click', function (e) {
    if (!$(this).hasClass('disabled')) {
        $("#PayModal").modal("show");
        var slab = $('[name=slab_id]').val();
        var method = $('[name=pay-method]:checked').val();
        $.post('/tariff/select/payment', {slab: slab, p_method: method}, function (data) {
            $("#PayModal .modal-dialog").addClass('modal-lg');
            $("#PayModal .modal-body").html(data);
            bankTransferComplite();
            changePaymentMethod();
            getPayModal();
        });
    }
    return false;
});


function Latin2(obj) {
    if (/^[a-zA-Z0-9.\-]*?$/.test(obj.value)) {
        obj.defaultValue = obj.value;
        $("#alerter").fadeOut('slow');
    }
    else {
        obj.value = obj.defaultValue;

        $("#alerter").show();
    }
}
function whoisx(dom) {
    $(".dom_w").removeClass("dom_w");
    $(".cler").remove();
    var temp = dom.replace(".", "");
    $("#button_" + temp).html('<div class="cler backs"></div><a class="btn_col dom_w" onclick="whoisx(\'' + dom + '\')" href="javascript:void(0);">Whois info</a>');
    $.get("/whois/ajax-domain", {domain: dom}).done(function (data) {
        var temp = dom.replace(".", "");
        $("#button_" + temp).html('<div class="cler"><div class="sep"></div></div><a class="btn_col dom_w" onclick="whoisx(\'' + dom + '\')" href="javascript:void(0);">Whois info</a>');
        $("#whoisresult").html('<h3 class="wois_dom"><b>' + dom + ' </b> whois information</h3><pre>' + data + '</pre>');
        $("#whoisresult").slideDown('slow');

    });
}
function check() {
    $("#result").html('<table id="table"></table>');
    $("input:checkbox[name=tld]:checked").each(function () {
            var domain = $("#sld").val() + $(this).val();
            var temp = domain.replace(".", "");
            $("#table").append('<tr id="' + temp + '"><td class="domainname">' + domain + '</td><td class="status" id="status_' + temp + '"><div ><img src="/img/loading.gif"></div></td><td class="linker" id="button_' + temp + '"></td></tr>');
            $.get("/whois/ajax-available", {sld: $("#sld").val(), tld: $(this).val()}).done(function (data) {
                if (data == "ok") {
                    $("#status_" + temp).html("available");
                    $("#" + temp).addClass("available");
                    $("#button_" + temp).html('');
                } else {
                    $("#button_" + temp).html('<a class="btn_col" onclick="whoisx(\'' + domain + '\')" href="javascript:void(0);">Whois info</a>');
                    $("#status_" + temp).html("not available");
                    $("#" + temp).addClass("notavailable");
                }

            });

        }
    );
}

$(function () {
    $("#sld").keyup(function () {
        if ($("#sld").val() != "") check();
    });
    var liuk1 = true;
    $("#checkall").click(function (event) {
        liuk1 = false;
        if ($("#checkall").is(':checked')) {
            $("input:checkbox[name=tld]").prop('checked', true);
        } else {
            $("input:checkbox[name=tld]").prop('checked', false);
        }
        ;
    });
    $(".tldblock input:checkbox").click(function (event) {


        if ($("#sld").val() != "") check();
        var liuk = true;
        $("input:checkbox[name=tld]").each(function () {
                if (!$(this).is(':checked')) {
                    liuk = false
                }

            }
        );
        if (liuk1) {
            if (liuk) {
                $("#checkall").prop('checked', true);
            } else {
                $("#checkall").prop('checked', false);
            }
        } else {
            liuk1 = true;
        }

    });

});
function isPremiumMe(domain) {
    var status = false;
    for(var i = 0; i < premMe.length; i++){
        if(domain == premMe[i]){
            status = true;
            break;
        }
    }
    return status;
}
var premMe = [
    'downlaod.me',
    'homemortage.me',
    'gledaj.me',
    'ispeglaj.me',
    'isprzi.me',
    'izbegni.me',
    'izbjegni.me',
    'izglancaj.me',
    'izlijeci.me',
    'izljeci.me',
    'izmijesaj.me',
    'izmjesaj.me',
    'izmrvi.me',
    'iznevjeri.me',
    'izravnaj.me',
    'izvagaj.me',
    'izvezbaj.me',
    'izvezi.me',
    'izvjezbaj.me',
    'izvozi.me',
    'izvrsi.me',
    'izvuci.me',
    'mijenjaj.me',
    'mjenjaj.me',
    'nacrtaj.me',
    'napumpaj.me',
    'njeguj.me',
    'obezbjedi.me',
    'obiljezi.me',
    'objasni.me',
    'obradjuj.me',
    'obrukaj.me',
    'odnesi.me',
    'odslusaj.me',
    'odvedi.me',
    'odvoji.me',
    'ogovaraj.me',
    'okupaj.me',
    'ometaj.me',
    'opljackaj.me',
    'osvetli.me',
    'osvjetli.me',
    'otkljucaj.me',
    'otkrij.me',
    'ozledi.me',
    'ozlijedi.me',
    'ozljedi.me',
    'ozraci.me',
    'pauziraj.me',
    'pocepaj.me',
    'pocijepaj.me',
    'pocjepaj.me',
    'pokrij.me',
    'pomijesaj.me',
    'pomjesaj.me',
    'posavetuj.me',
    'posavjetuj.me',
    'posvadjaj.me',
    'potvrdi.me',
    'povrjedi.me',
    'priblizi.me',
    'prihvati.me',
    'prikljuci.me',
    'proizvedi.me',
    'proknjizi.me',
    'promijesaj.me',
    'razdvoji.me',
    'razjasni.me',
    'razlikuj.me',
    'razoruzaj.me',
    'sacuvaj.me',
    'saslusaj.me',
    'savjetuj.me',
    'skrati.me',
    'skupljaj.me',
    'skuvaj.me',
    'slusaj.me',
    'sprjeci.me',
    'strijeljaj.me',
    'strjeljaj.me',
    'ubrizgaj.me',
    'udalji.me',
    'udvostruci.me',
    'uhvati.me',
    'ukljuci.me',
    'uklopi.me',
    'ukrcaj.me',
    'umnozi.me',
    'unaprijedi.me',
    'upecaj.me',
    'upitaj.me',
    'upozori.me',
    'uprljaj.me',
    'utopli.me',
    'uvrijedi.me',
    'uzdigni.me',
    'uznemiravaj.me',
    'uznemiri.me',
    'vezbaj.me',
    'vjezbaj.me',
    'zabiljezi.me',
    'zadrzi.me',
    'zagrij.me',
    'zahtevaj.me',
    'zahtijevaj.me',
    'zahtjevaj.me',
    'zakljucaj.me',
    'zakopaj.me',
    'zalijepi.me',
    'zaljepi.me',
    'zasijeni.me',
    'zatvori.me',
    'zazidaj.me',
    'zblizi.me',
    'zloupotrijebi.me',
    'zloupotrjebi.me',
    'bioera.me',
    'mechantaccount.me',
    'employement.me',
    'videagames.me',
    'abraza.me',
    'analiziraj.me',
    'aplaude.me',
    'arregla.me',
    'atrapa.me',
    'bloguj.me',
    'blokiraj.me',
    'bombarduj.me',
    'castiga.me',
    'cekaj.me',
    'cenacon.me',
    'cenzurisi.me',
    'cercada.me',
    'chiedia.me',
    'chiedidi.me',
    'chiediloa.me',
    'cijeni.me',
    'cjeni.me',
    'consiente.me',
    'crtaj.me',
    'cuvaj.me',
    'dekodiraj.me',
    'demuestra.me',
    'despide.me',
    'dibuja.me',
    'docekaj.me',
    'dodirni.me',
    'dohvati.me',
    'donizete.me',
    'dozovi.me',
    'dozvoli.me',
    'dupliraj.me',
    'enciende.me',
    'entiende.me',
    'escoje.me',
    'golpea.me',
    'graziea.me',
    'grupisi.me',
    'gurni.me',
    'ignorisi.me',
    'imenuj.me',
    'informisi.me',
    'interesuj.me',
    'iskljuci.me',
    'iskoristi.me',
    'ispeci.me',
    'ispitaj.me',
    'isplati.me',
    'isporuci.me',
    'isprati.me',
    'isprazni.me',
    'isprogramiraj.me',
    'istopi.me',
    'izazovi.me',
    'izbrisi.me',
    'izbroj.me',
    'izgradi.me',
    'izgubi.me',
    'izleci.me',
    'izmeni.me',
    'izmeri.me',
    'izmesaj.me',
    'izmijeni.me',
    'izmjeni.me',
    'izmjeri.me',
    'iznenadi.me',
    'izneveri.me',
    'izostavi.me',
    'izracunaj.me',
    'izrezi.me',
    'izvini.me',
    'izvinite.me',
    'kontaktiraj.me',
    'kopiraj.me',
    'kuvaj.me',
    'licitiraj.me',
    'ljubi.me',
    'llora.me',
    'markiraj.me',
    'menjaj.me',
    'nabavi.me',
    'nagomilaj.me',
    'naljuti.me',
    'nametni.me',
    'napadni.me',
    'napisi.me',
    'naplati.me',
    'napomeni.me',
    'napravi.me',
    'napuni.me',
    'napusti.me',
    'naruci.me',
    'naslikaj.me',
    'nazovi.me',
    'negiraj.me',
    'neguj.me',
    'obavesti.me',
    'obavezi.me',
    'obavijesti.me',
    'obavjesti.me',
    'obelezi.me',
    'obesi.me',
    'obezbedi.me',
    'obezbijedi.me',
    'obidji.me',
    'obilazi.me',
    'objesi.me',
    'obmani.me',
    'oboji.me',
    'obori.me',
    'obracunaj.me',
    'obradi.me',
    'obrisi.me',
    'ocaraj.me',
    'ocijeni.me',
    'ocjeni.me',
    'odbaci.me',
    'odbrani.me',
    'odglumi.me',
    'odigraj.me',
    'odlozi.me',
    'odmeri.me',
    'odmjeri.me',
    'odobri.me',
    'odstampaj.me',
    'ofrece.me',
    'ogradi.me',
    'ogranici.me',
    'ohrabri.me',
    'okiti.me',
    'okruzi.me',
    'opeci.me',
    'opisi.me',
    'opravdaj.me',
    'oprosti.me',
    'oraspolozi.me',
    'osamari.me',
    'oseti.me',
    'osisaj.me',
    'osjeti.me',
    'oslabi.me',
    'oslobodi.me',
    'osnuj.me',
    'ospori.me',
    'osposobi.me',
    'osramoti.me',
    'ostavi.me',
    'osteti.me',
    'osudi.me',
    'osusi.me',
    'osvijetli.me',
    'osvoji.me',
    'otkoci.me',
    'otkupi.me',
    'otopi.me',
    'otvori.me',
    'ovlasti.me',
    'oznaci.me',
    'parkiraj.me',
    'parlacon.me',
    'parlodi.me',
    'patentiraj.me',
    'pecatiraj.me',
    'pensaa.me',
    'pobedi.me',
    'pobijedi.me',
    'pobjedi.me',
    'podeli.me',
    'podesi.me',
    'podigni.me',
    'podijeli.me',
    'podjeli.me',
    'podmazi.me',
    'podseti.me',
    'podsjeti.me',
    'pogledaj.me',
    'pogodi.me',
    'pohvali.me',
    'pojedi.me',
    'pokazi.me',
    'pokori.me',
    'pokusaj.me',
    'pokvari.me',
    'poljubi.me',
    'polozi.me',
    'pomesaj.me',
    'pomirisi.me',
    'ponesi.me',
    'ponovi.me',
    'popisi.me',
    'poredjaj.me',
    'poruci.me',
    'posjeti.me',
    'poslusaj.me',
    'posmatraj.me',
    'postedi.me',
    'postuj.me',
    'povezi.me',
    'povredi.me',
    'povrijedi.me',
    'povuci.me',
    'pozajmi.me',
    'pozdravi.me',
    'pozeli.me',
    'pozovi.me',
    'pravdaj.me',
    'prebaci.me',
    'predaj.me',
    'predstavi.me',
    'pregledaj.me',
    'prekni.me',
    'prendi.me',
    'prepisi.me',
    'preplasi.me',
    'prepoznaj.me',
    'presnimi.me',
    'prevari.me',
    'prevezi.me',
    'prevozi.me',
    'prikupi.me',
    'primami.me',
    'prisvoji.me',
    'pritvori.me',
    'privezi.me',
    'privuci.me',
    'priznaj.me',
    'probudi.me',
    'proceni.me',
    'procijeni.me',
    'procjeni.me',
    'prodji.me',
    'produzi.me',
    'programiraj.me',
    'promeni.me',
    'promesaj.me',
    'promijeni.me',
    'promjeni.me',
    'promjesaj.me',
    'pronadji.me',
    'propusti.me',
    'proracunaj.me',
    'prosiri.me',
    'prosvetli.me',
    'prosvijetli.me',
    'prosvjetli.me',
    'prouci.me',
    'proveri.me',
    'provjeri.me',
    'provozaj.me',
    'rasiri.me',
    'raspakuj.me',
    'rastavi.me',
    'razocaraj.me',
    'razonodi.me',
    'razvedi.me',
    'razveseli.me',
    'rezervisi.me',
    'riguarda.me',
    'rijesi.me',
    'rjesi.me',
    'sagradi.me',
    'sahrani.me',
    'sakri.me',
    'sakupi.me',
    'sanjaj.me',
    'sarmiraj.me',
    'savetuj.me',
    'scegli.me',
    'scrivia.me',
    'simbolizuj.me',
    'simphaty.me',
    'simuliraj.me',
    'sinceramente.me',
    'sisaj.me',
    'skoluj.me',
    'slazi.me',
    'slikaj.me',
    'slomi.me',
    'smrvi.me',
    'snizi.me',
    'soloper.me',
    'spakuj.me',
    'spali.me',
    'spamuj.me',
    'spoji.me',
    'spreci.me',
    'sprijatelji.me',
    'sprijeci.me',
    'spusti.me',
    'stampaj.me',
    'stekni.me',
    'stisni.me',
    'streljaj.me',
    'stvori.me',
    'suplica.me',
    'svezi.me',
    'telefoniraj.me',
    'traduze.me',
    'tranne.me',
    'trazi.me',
    'tresni.me',
    'tuttodi.me',
    'udahni.me',
    'udari.me',
    'udavi.me',
    'udruzi.me',
    'ugrabi.me',
    'uhapsi.me',
    'ujedini.me',
    'ukloni.me',
    'ukradi.me',
    'ukrasi.me',
    'ukrsti.me',
    'ulovi.me',
    'ulozi.me',
    'umrezi.me',
    'unapredi.me',
    'unesi.me',
    'unovci.me',
    'upali.me',
    'upari.me',
    'uplasi.me',
    'uplati.me',
    'upoznaj.me',
    'upropasti.me',
    'uputi.me',
    'uredi.me',
    'ustedi.me',
    'usvoji.me',
    'utesi.me',
    'utjesi.me',
    'uvredi.me',
    'uvuci.me',
    'uzbudi.me',
    'uzbuni.me',
    'vienida.me',
    'zabavi.me',
    'zabelezi.me',
    'zabrani.me',
    'zabrini.me',
    'zacrveni.me',
    'zadovolji.me',
    'zaduzi.me',
    'zagrli.me',
    'zagusi.me',
    'zainteresuj.me',
    'zakoci.me',
    'zakucaj.me',
    'zalepi.me',
    'zameni.me',
    'zamijeni.me',
    'zamjeni.me',
    'zamoli.me',
    'zaokruzi.me',
    'zapali.me',
    'zapecati.me',
    'zapocni.me',
    'zapostavi.me',
    'zaradi.me',
    'zaseni.me',
    'zasjeni.me',
    'zasluzi.me',
    'zastiti.me',
    'zaustavi.me',
    'zavedi.me',
    'zavezi.me',
    'zezni.me',
    'zidaj.me',
    'zloupotrebi.me',
    'zovni.me',
    'dontdeport.me',
    'palladium.me',
    'dedicatedserver.me',
    'dnserver.me',
    'dailup.me',
    'freesms.me',
    'blue-ray.me',
    'videocds.me',
    'h1sponsorship.me',
    'play-with.me',
    'peoplelocator.me',
    '1-866.me',
    'talk-to.me',
    'itindustry.me',
    'machinetools.me',
    'dotin.me',
    'computermemory.me',
    'webhostingservice.me',
    'popupblocker.me',
    'cement.me',
    'coolmovie.me',
    'themovie.me',
    'mp3player.me',
    'mp3store.me',
    'coffehouse.me',
    'addictedgames.me',
    'videosgratis.me',
    'hebrew.me',
    'wifelover.me',
    'abre.me',
    'apoya.me',
    'ascolta.me',
    'baci.me',
    'ceni.me',
    'chiama.me',
    'coje.me',
    'conoce.me',
    'construye.me',
    'drzi.me',
    'encuentra.me',
    'escribeme.me',
    'escucha.me',
    'espera.me',
    'exita.me',
    'explica.me',
    'grita.me',
    'grli.me',
    'guarda.me',
    'imaj.me',
    'lazi.me',
    'mazi.me',
    'moli.me',
    'mrzi.me',
    'muci.me',
    'observa.me',
    'oggi.me',
    'organiza.me',
    'ozeni.me',
    'piensa.me',
    'polomi.me',
    'poseti.me',
    'quiere.me',
    'resi.me',
    'saberi.me',
    'saluda.me',
    'senti.me',
    'skini.me',
    'skupi.me',
    'spari.me',
    'suena.me',
    'toca.me',
    'tuttoper.me',
    'udaj.me',
    'upisi.me',
    'uporedi.me',
    'utopi.me',
    'uzmi.me',
    'vezi.me',
    'vuci.me',
    'zapisi.me',
    'zavoli.me',
    'zovi.me',
    'uspassport.me',
    'speedtrap.me',
    'moshe.me',
    'choudhury.me',
    'durand.me',
    'fournier.me',
    'gheller.me',
    'odoherty.me',
    'horsesupplies.me',
    '1-800.me',
    'ought.me',
    'throughout.me',
    'veiling.me',
    'dialhome.me',
    'sheloves.me',
    'soccertv.me',
    'japanesecar.me',
    '1-877.me',
    '2ez.me',
    'petrochemical.me',
    'ebiz.me',
    'saleslead.me',
    'laundromats.me',
    'voiceoverip.me',
    'internethosting.me',
    'sitehosting.me',
    'dialin.me',
    'internetservice.me',
    'internettelephony.me',
    'computerhelp.me',
    'flashgames.me',
    'dvdrental.me',
    'movielist.me',
    'musicsearch.me',
    'funnyads.me',
    'moneytransfers.me',
    'collectionagency.me',
    'instantapprovals.me',
    'creditcounselor.me',
    'discountinsurance.me',
    'erepublik.me',
    'united-kingdom.me',
    'licenseplates.me',
    'usimmigration.me',
    'federalgrants.me',
    'fertilitydoctors.me',
    'alonso.me',
    'annalise.me',
    'callie.me',
    'declan.me',
    'isaiah.me',
    'jaclyn.me',
    'kareem.me',
    'esposito.me',
    'lefebvre.me',
    'zimmermann.me',
    'gators.me',
    'handloom.me',
    'fishingpole.me',
    'along.me',
    'localdirectory.me',
    'i-love.me',
    'could.me',
    'except.me',
    'regard.me',
    'would.me',
    'titanium.me',
    'rfid.me',
    'discountfor.me',
    'onlinepersonals.me',
    'orgs.me',
    'leanon.me',
    'prizefight.me',
    'until.me',
    'germancar.me',
    'dieselengine.me',
    'flyhome.me',
    'flyto.me',
    'europeanvacations.me',
    'casinoonnet.me',
    'phonesystem.me',
    'jobplacement.me',
    'itjob.me',
    'joblisting.me',
    'careertraining.me',
    'epost.me',
    'ipphone.me',
    'spamfilter.me',
    'cheapweb.me',
    'globalsearch.me',
    'crane.me',
    'movierentals.me',
    'newmovie.me',
    'gospelmusic.me',
    'itnews.me',
    'theway.me',
    'rupee.me',
    'myfinance.me',
    'primerate.me',
    'saginaw.me',
    'southbend.me',
    'townhall.me',
    'treatmentcenters.me',
    'lasereye.me',
    'porker.me',
    'manhunt.me',
    'elijah.me',
    'hailey.me',
    'rani.me',
    'fuchs.me',
    'hoffmann.me',
    'lucian.me',
    'martins.me',
    'mercier.me',
    'neumann.me',
    'schmitt.me',
    'homeforeclosures.me',
    'crosswordpuzzles.me',
    'entrance.me',
    'some.me',
    'despite.me',
    'might.me',
    'doitto.me',
    'bid4.me',
    'apple4.me',
    'homepc.me',
    'buyfrom.me',
    'flatpanel.me',
    'freepix.me',
    'voipservice.me',
    'naturalbeauty.me',
    'singlesonline.me',
    'tryst.me',
    'argue.me',
    'scouts.me',
    'dominate.me',
    'fieldhockey.me',
    'occur.me',
    'passover.me',
    'scrooge.me',
    'during.me',
    'carparking.me',
    'jetfares.me',
    'caribbeanvacations.me',
    'travelindia.me',
    'freepicks.me',
    'cheapgas.me',
    'informationtechnology.me',
    'copies.me',
    'officespace.me',
    'jointventure.me',
    'phonenumber.me',
    'telco.me',
    'findajob.me',
    'hotelmanagement.me',
    'blogster.me',
    'mails.me',
    'webconferencing.me',
    'freehoroscope.me',
    'musicdownload.me',
    'satelliteradio.me',
    'soapoperas.me',
    'goddess.me',
    'grandpa.me',
    'creditcare.me',
    'termlife.me',
    'dreamlife.me',
    'earning.me',
    'moneylenders.me',
    'fixedrate.me',
    'taxlawyer.me',
    'healthfood.me',
    'besa.me',
    'bianchi.me',
    'celebra.me',
    'chandra.me',
    'convence.me',
    'dava.me',
    'despierta.me',
    'fotografa.me',
    'gana.me',
    'gazi.me',
    'gusta.me',
    'habla.me',
    'odia.me',
    'posada.me',
    'primi.me',
    'publica.me',
    'secondo.me',
    'tipo.me',
    'tutto.me',
    'vende.me',
    'voli.me',
    'vota.me',
    'warez.me',
    'cityindex.me',
    'united-states.me',
    'unclesam.me',
    'habits.me',
    'fitnessfor.me',
    'grouphealth.me',
    'woody.me',
    'koch.me',
    'sanford.me',
    'bugs.me',
    'handicraft.me',
    '2you.me',
    '4all.me',
    'doitfor.me',
    'must.me',
    'should.me',
    'disgusts.me',
    'cdplayer.me',
    'tuxedorental.me',
    'flowerbasket.me',
    'cheapgifts.me',
    'discountperfumes.me',
    'buymusic.me',
    'payto.me',
    'mobilefone.me',
    'phonefree.me',
    'datingservice.me',
    'among.me',
    'males.me',
    'refuse.me',
    'last.me',
    'since.me',
    'aboard.me',
    'cancunhotels.me',
    'censored.me',
    'alternativeenergy.me',
    'mailinglist.me',
    'publicrelations.me',
    'psychologists.me',
    'infotech.me',
    'harddrive.me',
    'domainhosting.me',
    'computerrepair.me',
    'iqtest.me',
    'concerttickets.me',
    'amistad.me',
    'newsgroup.me',
    'videoproduction.me',
    'chosen.me',
    'devil.me',
    'fees.me',
    'fixedincome.me',
    'fastfood.me',
    'citycenter.me',
    'hanover.me',
    'greencard.me',
    'cerebralpalsy.me',
    'examine.me',
    'walkers.me',
    'eyedoctor.me',
    'securitysystem.me',
    'hana.me',
    'llama.me',
    'symbol.me',
    'concern.me',
    'curries.me',
    'sayto.me',
    'cheapcomputer.me',
    'onlineflowers.me',
    'bestphone.me',
    'cameraphone.me',
    'cellularservice.me',
    'dialing.me',
    'weddinggift.me',
    'commit.me',
    'suffer.me',
    'blondes.me',
    'hotdate.me',
    'greet.me',
    'depend.me',
    'femmes.me',
    'tournaments.me',
    'businessplan.me',
    'textiles.me',
    'jobsearch.me',
    'p2p.me',
    'exams.me',
    'joystick.me',
    'destiny.me',
    'exist.me',
    'badcredit.me',
    'earnings.me',
    'autofinancing.me',
    'mortage.me',
    'annonces.me',
    'calla.me',
    'exito.me',
    'invita.me',
    'jocuri.me',
    'publicidad.me',
    'visita.me',
    'tajmahal.me',
    'washingmachine.me',
    'yard.me',
    'criminallawyers.me',
    'haley.me',
    'colombo.me',
    'beaver.me',
    'mangas.me',
    'into.me',
    'you2.me',
    'infinite.me',
    'laser.me',
    'automobileparts.me',
    'cardealership.me',
    'cloths.me',
    'necktie.me',
    'freshflowers.me',
    'getmovies.me',
    'officechair.me',
    'freephone.me',
    'phonecard.me',
    'voipphone.me',
    'against.me',
    'agree.me',
    'gents.me',
    'thank.me',
    'dirtbike.me',
    'races.me',
    'before.me',
    'softbank.me',
    'advert.me',
    'till.me',
    'coal.me',
    'marketresearch.me',
    'conferencecall.me',
    'chatrooms.me',
    'fax.me',
    'antispyware.me',
    'spyware.me',
    'placement.me',
    'correct.me',
    'onlinedegree.me',
    'broadcasting.me',
    'prayer.me',
    'dead.me',
    'newlyweds.me',
    'cashadvance.me',
    'cashflow.me',
    'unitedkingdom.me',
    'fortress.me',
    'pimple.me',
    'contactlens.me',
    'courts.me',
    'lori.me',
    'samuel.me',
    'sharks.me',
    'amusement.me',
    'innovations.me',
    'lasers.me',
    'ebaby.me',
    'digitalcameras.me',
    'afford.me',
    'plasmatv.me',
    'fashiondesign.me',
    'petit.me',
    'corporategifts.me',
    'findit.me',
    'prepaidphone.me',
    'satellitephone.me',
    'blocks.me',
    'contribute.me',
    'blond.me',
    'complain.me',
    'toga.me',
    'cheerleaders.me',
    'past.me',
    'dawn.me',
    'sailboat.me',
    'airtravel.me',
    'discounttravel.me',
    'textile.me',
    'personnel.me',
    'recruiter.me',
    'blogging.me',
    'emails.me',
    'freehosting.me',
    'browser.me',
    'workflow.me',
    'fungames.me',
    'teenmodels.me',
    'flix.me',
    'musician.me',
    'musicvideo.me',
    'matrimony.me',
    'homeloan.me',
    'spices.me',
    'programas.me',
    'yonkers.me',
    'infrastructure.me',
    'authority.me',
    'nuclear.me',
    'bald.me',
    'impotence.me',
    'slimming.me',
    'homeimprovement.me',
    'decision.me',
    'stole.me',
    'levi.me',
    'monica.me',
    'samantha.me',
    'campgrounds.me',
    'dart.me',
    'across.me',
    'toward.me',
    'hill.me',
    'result.me',
    'caraudio.me',
    'carsale.me',
    'cheapcar.me',
    'digitalcamera.me',
    'classifiedads.me',
    'tabletpc.me',
    'sendflowers.me',
    'bachelor.me',
    'couples.me',
    'loves.me',
    'belong.me',
    'prize.me',
    'april.me',
    'soon.me',
    'arrive.me',
    'businesscards.me',
    'naturalgas.me',
    'mining.me',
    'payroll.me',
    'webdevelopment.me',
    'construction.me',
    'classic.me',
    'makemoney.me',
    'bogota.me',
    'embassy.me',
    'asbestos.me',
    'release.me',
    'jail.me',
    'prove.me',
    'hotproperty.me',
    'down.me',
    'ten.me',
    'three.me',
    'dvdplayer.me',
    'hometheater.me',
    'flowerdelivery.me',
    'cubancigars.me',
    'cause.me',
    'prevent.me',
    'riot.me',
    'freak.me',
    'tractors.me',
    'callcenter.me',
    'itjobs.me',
    'professor.me',
    'myblog.me',
    'templates.me',
    'certification.me',
    'perform.me',
    'opinion.me',
    'supernatural.me',
    'hell.me',
    'liability.me',
    'bubbles.me',
    'limit.me',
    'autoloan.me',
    'baja.me',
    'empleo.me',
    'mapa.me',
    'sans.me',
    'h1b.me',
    'cane.me',
    'dietplan.me',
    'settlement.me',
    'greenhouse.me',
    'pottery.me',
    'sew.me',
    'orange.me',
    'white.me',
    'five.me',
    'prefer.me',
    'shall.me',
    'looks.me',
    'petsupplies.me',
    'proshop.me',
    'glamorous.me',
    'blonde.me',
    'civil.me',
    'native.me',
    'diesel.me',
    'engine.me',
    'hedonism.me',
    'guides.me',
    'guest.me',
    '2006.me',
    'telemarketing.me',
    'warehouse.me',
    'animation.me',
    'newmusic.me',
    'mother.me',
    'territory.me',
    'fertilizer.me',
    'paralegal.me',
    'dock.me',
    'red.me',
    'east.me',
    'six.me',
    'concerning.me',
    'suppose.me',
    'invention.me',
    'shoppers.me',
    'lifts.me',
    'certificates.me',
    'sportinggoods.me',
    'wirelessphone.me',
    'hate.me',
    'discuss.me',
    'prom.me',
    'airlinetickets.me',
    'charters.me',
    'placebets.me',
    'biotech.me',
    'freight.me',
    'coed.me',
    'ged.me',
    'heaven.me',
    'funeral.me',
    'credits.me',
    'economy.me',
    'damage.me',
    'fee.me',
    'gain.me',
    'carloan.me',
    'mozambique.me',
    'mine.me',
    'lips.me',
    'mailbox.me',
    'wolf.me',
    'quilting.me',
    'forest.me',
    'english.me',
    'two.me',
    'disappear.me',
    'indicate.me',
    'ebid.me',
    'ecard.me',
    'demand.me',
    'matter.me',
    'express.me',
    'influence.me',
    'person.me',
    '2007.me',
    '2008.me',
    '2010.me',
    'advertising.me',
    'laugh.me',
    'freegames.me',
    'cross.me',
    'spicy.me',
    'force.me',
    'smoking.me',
    'fountain.me',
    'over.me',
    'consist.me',
    'contain.me',
    'separate.me',
    'scanner.me',
    'cushion.me',
    'obtain.me',
    'lover.me',
    'member.me',
    'locals.me',
    'night.me',
    'fall.me',
    'sportscar.me',
    'vans.me',
    '2009.me',
    '2011.me',
    'machine.me',
    'webdesign.me',
    'nanny.me',
    'paraguay.me',
    'immigration.me',
    'obesity.me',
    'court.me',
    'reduce.me',
    'west.me',
    'general.me',
    'confide.me',
    'describe.me',
    'fasten.me',
    'intend.me',
    'report.me',
    'petite.me',
    'receive.me',
    'carpets.me',
    'ringtone.me',
    'fights.me',
    'shout.me',
    'desire.me',
    'pda.me',
    'golfcourses.me',
    'pharma.me',
    'engineer.me',
    'families.me',
    'father.me',
    'offshore.me',
    'sub.me',
    'zimbabwe.me',
    'nets.me',
    'climb.me',
    'remain.me',
    'usedcar.me',
    'panty.me',
    'barcode.me',
    'mobilephone.me',
    'connection.me',
    'current.me',
    'tonight.me',
    'partners.me',
    'major.me',
    'takeout.me',
    'homeinsurance.me',
    'empire.me',
    'plasma.me',
    'wind.me',
    'punt.me',
    'realize.me',
    'require.me',
    'camcorder.me',
    'shoppingcart.me',
    'rebates.me',
    'calls.me',
    'hope.me',
    'times.me',
    'conference.me',
    'processor.me',
    'dollars.me',
    'option.me',
    'national.me',
    'gibraltar.me',
    'foot.me',
    'cardiology.me',
    'justice.me',
    'wild.me',
    'lofts.me',
    'place.me',
    'outside.me',
    'seem.me',
    'shut.me',
    'spill.me',
    'ecards.me',
    'subscription.me',
    'revolution.me',
    'futbol.me',
    'aviation.me',
    'airways.me',
    'buses.me',
    'hoteldeals.me',
    'cartoon.me',
    'cinema.me',
    'boys.me',
    'pacific.me',
    'caught.me',
    'own.me',
    'direct.me',
    'onto.me',
    '2go.me',
    'consider.me',
    'fold.me',
    'mention.me',
    'throw.me',
    'catalog.me',
    'sofas.me',
    'rebate.me',
    'summer.me',
    'port.me',
    'outsource.me',
    'wood.me',
    'staffing.me',
    'brackets.me',
    'understand.me',
    'euro.me',
    'beef.me',
    'bebe.me',
    'casa.me',
    'classified.me',
    'recycling.me',
    'underneath.me',
    'regarding.me',
    'through.me',
    'youare.me',
    'knock.me',
    'recognize.me',
    'relate.me',
    'lamps.me',
    'jewelers.me',
    'microwave.me',
    'daily.me',
    'tourist.me',
    'hardware.me',
    'campus.me',
    'annuity.me',
    'lunch.me',
    'uruguay.me',
    'physician.me',
    'hospitals.me',
    'appear.me',
    'repeat.me',
    'tend.me',
    'freebies.me',
    'run.me',
    'xmas.me',
    'telecom.me',
    'training.me',
    'sound.me',
    'kid.me',
    'honeymoon.me',
    'been.me',
    'confirm.me',
    'sort.me',
    'takeaway.me',
    'experience.me',
    'agriculture.me',
    'sin.me',
    'die.me',
    'dreams.me',
    'black.me',
    'arrange.me',
    'begin.me',
    'dish.me',
    'personal.me',
    'newcar.me',
    'tires.me',
    'rail.me',
    '22.me',
    '46.me',
    '90.me',
    '0n.me',
    '0x.me',
    '1c.me',
    '1g.me',
    '1h.me',
    '1l.me',
    '1s.me',
    '1z.me',
    '2c.me',
    '3h.me',
    '3l.me',
    '3o.me',
    '3r.me',
    '3v.me',
    '3w.me',
    '4a.me',
    '4e.me',
    '4j.me',
    '4w.me',
    '5h.me',
    '5j.me',
    '5q.me',
    '5t.me',
    '5y.me',
    '6n.me',
    '6q.me',
    '7c.me',
    '7d.me',
    '7k.me',
    '7s.me',
    '7u.me',
    '8g.me',
    '8t.me',
    '8y.me',
    '9f.me',
    '9h.me',
    '9q.me',
    'c1.me',
    'c7.me',
    'd5.me',
    'd6.me',
    'e0.me',
    'e9.me',
    'f0.me',
    'f6.me',
    'g0.me',
    'g6.me',
    'h7.me',
    'i7.me',
    'j9.me',
    'k5.me',
    'k8.me',
    'l9.me',
    'm9.me',
    'n5.me',
    'n7.me',
    'n9.me',
    'o3.me',
    'p0.me',
    'p7.me',
    'q3.me',
    'q9.me',
    'r3.me',
    'u6.me',
    'v3.me',
    'w5.me',
    'w6.me',
    'x0.me',
    'z6.me',
    'jmm.me',
    'oye.me',
    'was.me',
    'shake.me',
    'rehab.me',
    'bets.me',
    '34.me',
    '44.me',
    '51.me',
    '68.me',
    '0d.me',
    '0f.me',
    '0h.me',
    '0m.me',
    '2h.me',
    '2o.me',
    '2q.me',
    '2v.me',
    '4v.me',
    '5d.me',
    '5e.me',
    '6b.me',
    '6h.me',
    '6w.me',
    '7e.me',
    '7x.me',
    '8n.me',
    '8q.me',
    '8x.me',
    '9d.me',
    '9o.me',
    '9r.me',
    'b9.me',
    'd7.me',
    'd9.me',
    'e5.me',
    'e8.me',
    'f5.me',
    'h5.me',
    'h6.me',
    'i3.me',
    'j5.me',
    'j6.me',
    'k0.me',
    'k3.me',
    'k7.me',
    'l3.me',
    'l5.me',
    'l6.me',
    'n8.me',
    'o0.me',
    'o6.me',
    'o7.me',
    'o9.me',
    'p6.me',
    'p9.me',
    'q0.me',
    'q6.me',
    'q7.me',
    's0.me',
    't7.me',
    'w0.me',
    'w7.me',
    'x9.me',
    'y0.me',
    'z0.me',
    'z7.me',
    'z8.me',
    'nom.me',
    'pos.me',
    'claim.me',
    '17.me',
    '49.me',
    '56.me',
    '62.me',
    '70.me',
    '82.me',
    '0a.me',
    '1a.me',
    '2p.me',
    '3c.me',
    '3z.me',
    '4r.me',
    '5n.me',
    '6l.me',
    '6r.me',
    '7r.me',
    '7v.me',
    '8d.me',
    '8e.me',
    '8i.me',
    '8p.me',
    '8v.me',
    'b0.me',
    'b6.me',
    'b7.me',
    'c5.me',
    'c9.me',
    'e7.me',
    'j3.me',
    'm3.me',
    'o1.me',
    'o5.me',
    'q1.me',
    'q5.me',
    'r0.me',
    'r6.me',
    'r8.me',
    's1.me',
    's5.me',
    's9.me',
    't0.me',
    't3.me',
    't5.me',
    'u0.me',
    'u3.me',
    'v9.me',
    'x6.me',
    'y9.me',
    'z9.me',
    'rise.me',
    'gold.me',
    '35.me',
    '1y.me',
    '2k.me',
    '3p.me',
    '4x.me',
    '5x.me',
    'a9.me',
    'c0.me',
    'f9.me',
    'g9.me',
    'i0.me',
    'm5.me',
    'p5.me',
    'u5.me',
    'v7.me',
    'y6.me',
    'y7.me',
    'z3.me',
    'may.me',
    '16.me',
    '59.me',
    '1x.me',
    '3a.me',
    '3e.me',
    '6x.me',
    'b3.me',
    'c3.me',
    'g5.me',
    'g7.me',
    'h9.me',
    'i1.me',
    'i6.me',
    'j7.me',
    'k6.me',
    'l0.me',
    'm0.me',
    'm7.me',
    'p1.me',
    'q8.me',
    's7.me',
    'u7.me',
    'u9.me',
    'v6.me',
    'w3.me',
    'x1.me',
    'x3.me',
    'y1.me',
    'z5.me',
    'press.me',
    'within.me',
    'seek.me',
    'cheap.me',
    'a3.me',
    'h8.me',
    'm6.me',
    'r5.me',
    'y5.me',
    'z1.me',
    'speak.me',
    '95.me',
    'o8.me',
    'w9.me',
    'export.me',
    'vpn.me',
    'learn.me',
    'tune.me',
    'care.me',
    'choose.me',
    'sit.me',
    '15.me',
    'j1.me',
    'm8.me',
    'kit.me',
    'off.me',
    'form.me',
    'pamper.me',
    '21.me',
    '31.me',
    'u1.me',
    'sale.me',
    'gamble.me',
    'q4.me',
    'produce.me',
    '13.me',
    'b8.me',
    't6.me',
    'but.me',
    '14.me',
    '23.me',
    '45.me',
    '85.me',
    '247.me',
    'a0.me',
    'n3.me',
    'v8.me',
    '12.me',
    '33.me',
    'd3.me',
    'd8.me',
    'f3.me',
    'f8.me',
    'g3.me',
    'n6.me',
    't8.me',
    'w1.me',
    'x5.me',
    'y3.me',
    'flash.me',
    'b1.me',
    'c6.me',
    'd2.me',
    'm1.me',
    'n1.me',
    'n4.me',
    'r1.me',
    'v5.me',
    'w8.me',
    'x7.me',
    'z2.me',
    'walk.me',
    'upon.me',
    'fill.me',
    'notice.me',
    '80.me',
    '0123.me',
    'a7.me',
    'd1.me',
    'h1.me',
    'h3.me',
    'v4.me',
    'x8.me',
    'f1.me',
    'j4.me',
    'j8.me',
    'k1.me',
    'm4.me',
    'n2.me',
    'q2.me',
    's4.me',
    'z4.me',
    'ads.me',
    'supply.me',
    'work.me',
    'huge.me',
    'tease.me',
    '25.me',
    'a5.me',
    'i8.me',
    'i9.me',
    'l1.me',
    'w4.me',
    'weather.me',
    'a8.me',
    'c8.me',
    'j2.me',
    'y2.me',
    'b5.me',
    'l2.me',
    'p3.me',
    'r9.me',
    's2.me',
    'cut.me',
    'f2.me',
    'o4.me',
    't2.me',
    't4.me',
    'v1.me',
    'fast.me',
    'nasty.me',
    '50.me',
    'i5.me',
    'act.me',
    'grow.me',
    '18.me',
    'h4.me',
    'd4.me',
    'e1.me',
    'e2.me',
    'g2.me',
    'i4.me',
    'p4.me',
    'r4.me',
    'u4.me',
    'y4.me',
    'beyond.me',
    'e3.me',
    'i2.me',
    'x4.me',
    'hold.me',
    'carry.me',
    '2u.me',
    'a2.me',
    'c4.me',
    'e4.me',
    'g1.me',
    'credit.me',
    'improve.me',
    'easy.me',
    'say.me',
    'v2.me',
    'a4.me',
    'k4.me',
    'm2.me',
    'x2.me',
    'w2.me',
    '69.me',
    'l4.me',
    'p2.me',
    'b2.me',
    'f4.me',
    'o2.me',
    'h2.me',
    'r7.me',
    'g4.me',
    'promote.me',
    'big.me',
    'stop.me',
    'k2.me',
    '20.me',
    '30.me',
    '420.me',
    '24.me',
    '5k.me',
    'are.me',
    'include.me',
    'refresh.me',
    '98.me',
    'a1.me',
    'best.me',
    '2b.me',
    'k9.me',
    'mq.me',
    'dare.me',
    'zv.me',
    'hosting.me',
    'games.me',
    'homes.me',
    'touch.me',
    '89.me',
    'kz.me',
    'zq.me',
    'bq.me',
    'oq.me',
    'zk.me',
    'uq.me',
    'kx.me',
    '3g.me',
    '3m.me',
    'qg.me',
    'uj.me',
    'uz.me',
    'zp.me',
    '99.me',
    'jq.me',
    'qo.me',
    'rb.me',
    'xy.me',
    'zm.me',
    'casino.me',
    '88.me',
    'hx.me',
    'vq.me',
    'yf.me',
    'pz.me',
    'qn.me',
    'qz.me',
    'uy.me',
    'fq.me',
    'qk.me',
    'tq.me',
    'wz.me',
    'ey.me',
    'jn.me',
    'oj.me',
    'pq.me',
    'rz.me',
    '4u.me',
    'ik.me',
    'ov.me',
    'qw.me',
    'qy.me',
    'sx.me',
    'vl.me',
    'xr.me',
    'yn.me',
    'yv.me',
    'zl.me',
    'hv.me',
    'nq.me',
    'qv.me',
    'lz.me',
    'qx.me',
    'rl.me',
    'ud.me',
    'uf.me',
    'wt.me',
    'zb.me',
    'zf.me',
    'zg.me',
    'zj.me',
    'fj.me',
    'fz.me',
    'gx.me',
    'hw.me',
    'jx.me',
    'oi.me',
    'u2.me',
    'wq.me',
    'xu.me',
    'zt.me',
    'zx.me',
    'zy.me',
    'gj.me',
    'hz.me',
    'qh.me',
    'qs.me',
    'vh.me',
    'vj.me',
    'vx.me',
    'vy.me',
    'xb.me',
    'xv.me',
    'yc.me',
    'yg.me',
    'yz.me',
    'zd.me',
    'eg.me',
    'ej.me',
    'fo.me',
    'gz.me',
    'ih.me',
    'jb.me',
    'jz.me',
    'kb.me',
    'kh.me',
    'kq.me',
    'lk.me',
    'lu.me',
    'pn.me',
    'ql.me',
    'qp.me',
    'rq.me',
    'sy.me',
    'tg.me',
    'tz.me',
    'ug.me',
    'uh.me',
    'vt.me',
    'wg.me',
    'xc.me',
    'xg.me',
    'xz.me',
    'yk.me',
    'yw.me',
    'zw.me',
    'ah.me',
    'bn.me',
    'ek.me',
    'eo.me',
    'fh.me',
    'hh.me',
    'kv.me',
    'lj.me',
    'nz.me',
    'oa.me',
    'pe.me',
    'py.me',
    'qb.me',
    'qe.me',
    'qf.me',
    'tp.me',
    'uo.me',
    'vz.me',
    'wf.me',
    'wj.me',
    'xd.me',
    'zc.me',
    'ae.me',
    'ay.me',
    'dz.me',
    'fy.me',
    'gk.me',
    'gs.me',
    'gv.me',
    'gy.me',
    'ix.me',
    'jf.me',
    'jv.me',
    'nr.me',
    'nt.me',
    'qd.me',
    'qj.me',
    'sl.me',
    'tw.me',
    'xj.me',
    'xk.me',
    'xn.me',
    'xs.me',
    'bz.me',
    'cx.me',
    'cz.me',
    'dq.me',
    'ee.me',
    'jw.me',
    'jy.me',
    'kf.me',
    'kn.me',
    'lr.me',
    'lw.me',
    'lx.me',
    'mv.me',
    'ng.me',
    'nx.me',
    'pw.me',
    'px.me',
    'qa.me',
    'qc.me',
    'rg.me',
    'tf.me',
    'ub.me',
    'vg.me',
    'wn.me',
    'xw.me',
    'yx.me',
    'zh.me',
    'aq.me',
    'aw.me',
    'ax.me',
    'ck.me',
    'cq.me',
    'ef.me',
    'hl.me',
    'hs.me',
    'ij.me',
    'jh.me',
    'ku.me',
    'lq.me',
    'np.me',
    'qm.me',
    'rr.me',
    'sk.me',
    'sz.me',
    'vm.me',
    'wb.me',
    'xh.me',
    'xq.me',
    'yj.me',
    'yq.me',
    'yr.me',
    'zr.me',
    'zs.me',
    'aj.me',
    'bf.me',
    'bx.me',
    'eh.me',
    'ew.me',
    'gl.me',
    'gu.me',
    'hf.me',
    'hk.me',
    'hn.me',
    'hy.me',
    'jl.me',
    'lh.me',
    'ml.me',
    'mz.me',
    'sq.me',
    'tk.me',
    'ue.me',
    'uw.me',
    'xf.me',
    'yp.me',
    'yy.me',
    'ze.me',
    'zo.me',
    'fk.me',
    'gq.me',
    'oe.me',
    'oo.me',
    'qt.me',
    'ri.me',
    'rw.me',
    'sh.me',
    'sn.me',
    'ux.me',
    'wr.me',
    'zn.me',
    'dw.me',
    'dy.me',
    'ea.me',
    'fv.me',
    'iy.me',
    'jt.me',
    'kj.me',
    'kp.me',
    'mg.me',
    'mx.me',
    'qu.me',
    'rk.me',
    'sb.me',
    'sv.me',
    'ws.me',
    'wx.me',
    'xt.me',
    'yl.me',
    'b4.me',
    'cy.me',
    'dh.me',
    'fg.me',
    'fp.me',
    'iw.me',
    'ji.me',
    'ns.me',
    'pf.me',
    'pi.me',
    'pu.me',
    'vf.me',
    'wk.me',
    'wm.me',
    'xx.me',
    'yb.me',
    'ys.me',
    'bh.me',
    'cr.me',
    'pv.me',
    'sj.me',
    'vw.me',
    'yd.me',
    'yh.me',
    'bb.me',
    'dn.me',
    'dx.me',
    'fd.me',
    'kg.me',
    'lt.me',
    'rh.me',
    'casinos.me',
    'gr.me',
    'nn.me',
    'og.me',
    'pg.me',
    'zz.me',
    'bk.me',
    'ei.me',
    'gt.me',
    'js.me',
    'ju.me',
    'kl.me',
    'ln.me',
    'nw.me',
    'qi.me',
    'sr.me',
    'ts.me',
    'ul.me',
    'wl.me',
    'za.me',
    'db.me',
    'gc.me',
    'gh.me',
    'ly.me',
    'mk.me',
    'nv.me',
    'ot.me',
    'su.me',
    'tn.me',
    'ut.me',
    'cg.me',
    'ep.me',
    'ht.me',
    'jj.me',
    'ks.me',
    'lc.me',
    'qr.me',
    'ry.me',
    'uv.me',
    'bl.me',
    'cj.me',
    'fs.me',
    'gi.me',
    'jm.me',
    'mh.me',
    'od.me',
    'ou.me',
    'tr.me',
    'wu.me',
    'ba.me',
    'cf.me',
    'cw.me',
    'fw.me',
    'gn.me',
    'pd.me',
    'pt.me',
    'sp.me',
    'vv.me',
    'wv.me',
    'xa.me',
    'bm.me',
    'ci.me',
    'cu.me',
    'dg.me',
    'dk.me',
    'dt.me',
    'hc.me',
    'hm.me',
    'ib.me',
    'nf.me',
    'rj.me',
    'tj.me',
    'wc.me',
    'af.me',
    'ch.me',
    'dv.me',
    'eb.me',
    'jk.me',
    'kt.me',
    'kw.me',
    'lb.me',
    'ld.me',
    'oc.me',
    'pj.me',
    'ru.me',
    'vs.me',
    'ec.me',
    'jg.me',
    'mc.me',
    'pk.me',
    'tl.me',
    'xp.me',
    'ym.me',
    'bc.me',
    'gp.me',
    'lf.me',
    'mu.me',
    'nb.me',
    'td.me',
    'xi.me',
    'xo.me',
    'yi.me',
    'ab.me',
    'ao.me',
    'bo.me',
    'bv.me',
    'fe.me',
    'ir.me',
    'iu.me',
    'km.me',
    'ls.me',
    'nd.me',
    'rp.me',
    'uc.me',
    've.me',
    'vp.me',
    'wy.me',
    'ye.me',
    'yu.me',
    'fa.me',
    'fc.me',
    'fi.me',
    'he.me',
    'ka.me',
    'kk.me',
    'lo.me',
    'nh.me',
    'nl.me',
    'om.me',
    'xl.me',
    'df.me',
    'eq.me',
    'er.me',
    'ev.me',
    'fn.me',
    'jp.me',
    'kc.me',
    'kr.me',
    'ni.me',
    'ob.me',
    'oz.me',
    'wh.me',
    'bd.me',
    'cb.me',
    'gw.me',
    'jc.me',
    'mj.me',
    'ra.me',
    'rc.me',
    'vo.me',
    'wd.me',
    'xe.me',
    'cm.me',
    'sd.me',
    'ss.me',
    'cs.me',
    'ds.me',
    'gb.me',
    'iz.me',
    'lm.me',
    'tb.me',
    'ui.me',
    'vd.me',
    'zi.me',
    'hb.me',
    'll.me',
    'pl.me',
    'rs.me',
    'cn.me',
    'fr.me',
    'gd.me',
    'lv.me',
    'pa.me',
    'pb.me',
    'tc.me',
    'ce.me',
    'ct.me',
    'ff.me',
    'ke.me',
    'mm.me',
    'tm.me',
    '4k.me',
    'ak.me',
    'bw.me',
    'et.me',
    'hg.me',
    'kd.me',
    'lp.me',
    'mw.me',
    'ph.me',
    'qq.me',
    'rd.me',
    'ty.me',
    'um.me',
    'ur.me',
    'al.me',
    'av.me',
    'dd.me',
    'ja.me',
    'ow.me',
    'rt.me',
    'ic.me',
    'if.me',
    'po.me',
    'az.me',
    'bi.me',
    'cp.me',
    're.me',
    'st.me',
    'sw.me',
    'op.me',
    'yt.me',
    'lg.me',
    'mp.me',
    'ps.me',
    'sm.me',
    'ez.me',
    'ol.me',
    'au.me',
    'ky.me',
    'mb.me',
    'pr.me',
    'ar.me',
    'ii.me',
    'nu.me',
    'rf.me',
    'fl.me',
    'cc.me',
    'ia.me',
    'vi.me',
    'mn.me',
    'sc.me',
    'ap.me',
    'br.me',
    'an.me',
    'ma.me',
    'ag.me',
    'bs.me',
    'en.me',
    'wi.me',
    'ww.me',
    'nj.me',
    'nm.me',
    'vr.me',
    'ny.me',
    'wo.me',
    '6g.me',
    'aa.me',
    'fm.me',
    'fu.me',
    'dc.me',
    'uk.me',
    'bj.me',
    'xm.me',
    'du.me',
    'ox.me',
    'va.me',
    'mt.me',
    'bg.me',
    'ie.me',
    'md.me',
    'oh.me',
    'oy.me',
    '4g.me',
    'hd.me',
    'vn.me',
    'em.me',
    'ex.me',
    'nk.me',
    'ta.me',
    'th.me',
    'my.me',
    'rv.me',
    'am.me',
    'jr.me',
    'y8.me',
    'bp.me',
    'bu.me',
    'rn.me',
    '5g.me',
    'sf.me',
    'dj.me',
    'ha.me',
    'la.me',
    'around.me',
    '8k.me',
    'hq.me',
    'pc.me',
    'cd.me',
    'ed.me',
    'ms.me',
    'io.me',
    'ad.me',
    'iq.me',
    'mr.me',
    'ge.me',
    'im.me',
    'us.me',
    'gm.me',
    'hr.me',
    'fx.me',
    'ca.me',
    'dr.me',
    'it.me',
    'hp.me',
    'we.me',
    'tv.me',
    '0.me',
    '7.me',
    '8.me',
    '9.me',
    'b.me',
    'f.me',
    'j.me',
    'l.me',
    'p.me',
    'r.me',
    'u.me',
    'x.me',
    'z.me',
    'antivirus.me',
    'pass.me',
    'monster.me',
    'globe.me',
    'hunter.me',
    'imagine.me',
    'pull.me',
    'suit.me',
    'limited.me',
    'speaker.me',
    'spend.me',
    'fight.me',
    'stay.me',
    'realty.me',
    'cellphone.me',
    'head.me',
    'expect.me',
    'service.me',
    'scooter.me',
    'now.me',
    'worry.me',
    'deny.me',
    'burn.me',
    'measure.me',
    'destination.me',
    'refinance.me',
    'leather.me',
    'announce.me',
    'learning.me',
    'dad.me',
    'survive.me',
    'rest.me',
    'dangerous.me',
    'carsales.me',
    'clock.me',
    'resort.me',
    'stamps.me',
    'prepaid.me',
    'daj.me',
    'photographer.me',
    'isp.me',
    'tone.me',
    'lose.me',
    'handle.me',
    'turn.me',
    'cost.me',
    'netpoker.me',
    'decide.me',
    'cosmetic.me',
    'vcd.me',
    'hurt.me',
    'printer.me',
    'tablet.me',
    'lie.me',
    'eye.me',
    'men.me',
    'without.me',
    'lyn.me',
    'bingo.me',
    'beside.me',
    'wonder.me',
    '72.me',
    '76.me',
    'tra.me',
    'marketing.me',
    'teen.me',
    'attack.me',
    'cry.me',
    'moving.me',
    'wanted.me',
    'above.me',
    'ecommerce.me',
    'toy.me',
    '43.me',
    '94.me',
    'dss.me',
    'guy.me',
    'accept.me',
    'airfare.me',
    '29.me',
    '41.me',
    '97.me',
    '65.me',
    'asb.me',
    'yeu.me',
    'below.me',
    'finish.me',
    'strike.me',
    '58.me',
    '92.me',
    'glu.me',
    'have.me',
    '53.me',
    '1q.me',
    '2e.me',
    '5v.me',
    'ins.me',
    'large.me',
    'stand.me',
    '67.me',
    '81.me',
    '0u.me',
    '1f.me',
    '4h.me',
    '7j.me',
    '8r.me',
    'f7.me',
    'war.me',
    'enable.me',
    'retail.me',
    '27.me',
    '36.me',
    '48.me',
    '74.me',
    '1t.me',
    '2t.me',
    '3j.me',
    '3s.me',
    '5c.me',
    '7a.me',
    '7w.me',
    '8u.me',
    '9m.me',
    'our.me',
    'set.me',
    'control.me',
    '0i.me',
    '0o.me',
    '0q.me',
    '0w.me',
    '0z.me',
    '1m.me',
    '1v.me',
    '2g.me',
    '2j.me',
    '2x.me',
    '4p.me',
    '5p.me',
    '6i.me',
    '6u.me',
    '7f.me',
    '7h.me',
    '8f.me',
    '8z.me',
    '9b.me',
    '9e.me',
    '9i.me',
    '9k.me',
    '9z.me',
    'exports.me',
    'debit.me',
    'food.me',
    'nine.me',
    'affect.me',
    'warn.me',
    '38.me',
    '55.me',
    '78.me',
    '84.me',
    '87.me',
    '0s.me',
    '1j.me',
    '1o.me',
    '2a.me',
    '2r.me',
    '3f.me',
    '3n.me',
    '3y.me',
    '4n.me',
    '4s.me',
    '5a.me',
    '5m.me',
    '5z.me',
    '6o.me',
    '6s.me',
    '7o.me',
    '8m.me',
    '9t.me',
    '9x.me',
    'nav.me',
    'pvc.me',
    '19.me',
    '63.me',
    '0b.me',
    '0e.me',
    '0k.me',
    '1d.me',
    '2m.me',
    '2z.me',
    '3u.me',
    '4c.me',
    '4l.me',
    '4y.me',
    '5i.me',
    '5s.me',
    '6c.me',
    '6e.me',
    '6k.me',
    '6y.me',
    '7m.me',
    '7q.me',
    '7z.me',
    '8b.me',
    '8j.me',
    '9p.me',
    '9v.me',
    'd0.me',
    'h0.me',
    'l7.me',
    'n0.me',
    's6.me',
    't9.me',
    'v0.me',
    'non.me',
    'seed.me',
    'dream.me',
    'extend.me',
    'suggest.me',
    'scooters.me',
    'truck.me',
    'castle.me',
    'study.me',
    'reserve.me',
    'electronic.me',
    'wear.me',
    'cellular.me',
    'sleep.me',
    'happen.me',
    'promotion.me',
    'inn.me',
    'achieve.me',
    'notebooks.me',
    'bulk.me',
    'consulting.me',
    'amt.me',
    'movers.me',
    'dress.me',
    'blueray.me',
    'nation.me',
    'ocean.me',
    'pain.me',
    'steal.me',
    'housing.me',
    'isnot.me',
    'screen.me',
    'investment.me',
    'stick.me',
    'young.me',
    'bone.me',
    'divorce.me',
    'forsale.me',
    'warranty.me',
    'auctions.me',
    'paid.me',
    'uci.me',
    'beneath.me',
    'come.me',
    'used.me',
    'mms.me',
    'increase.me',
    'screw.me',
    'carpet.me',
    'single.me',
    'sportsbook.me',
    'exe.me',
    'patent.me',
    'between.me',
    'wait.me',
    'books.me',
    'dealer.me',
    'wholesale.me',
    'tools.me',
    'women.me',
    'prepare.me',
    'nin.me',
    'freaky.me',
    'password.me',
    'education.me',
    'sing.me',
    'worship.me',
    'girls.me',
    'destroy.me',
    'break.me',
    'dance.me',
    'behind.me',
    'eight.me',
    'avoid.me',
    'divide.me',
    '42.me',
    '83.me',
    'dew.me',
    'enter.me',
    'flight.me',
    'zzz.me',
    'helpdesk.me',
    'count.me',
    '61.me',
    '96.me',
    'lay.me',
    'opt.me',
    '26.me',
    '52.me',
    '222.me',
    '0y.me',
    '6m.me',
    '6p.me',
    '9u.me',
    'fat.me',
    'mean.me',
    '39.me',
    '91.me',
    '2f.me',
    '4q.me',
    '6v.me',
    '7t.me',
    '9l.me',
    '9y.me',
    'tow.me',
    'wash.me',
    '54.me',
    '71.me',
    '73.me',
    '1r.me',
    '2i.me',
    '2l.me',
    '5u.me',
    '7l.me',
    '8l.me',
    '8s.me',
    'out.me',
    '57.me',
    '79.me',
    '1i.me',
    '1k.me',
    '2y.me',
    '3i.me',
    '4m.me',
    '4t.me',
    '6z.me',
    '9c.me',
    '9g.me',
    '9n.me',
    'pit.me',
    'deal.me',
    '32.me',
    '37.me',
    '47.me',
    '64.me',
    '66.me',
    '75.me',
    '77.me',
    '86.me',
    '0j.me',
    '0t.me',
    '1e.me',
    '2n.me',
    '3k.me',
    '4o.me',
    '5r.me',
    '6a.me',
    '6d.me',
    '6f.me',
    '7b.me',
    '7i.me',
    '9j.me',
    '9w.me',
    'con.me',
    'resume.me',
    'voip.me',
    'fear.me',
    'settle.me',
    '28.me',
    '0c.me',
    '0l.me',
    '0p.me',
    '0v.me',
    '1u.me',
    '4i.me',
    '5b.me',
    '5l.me',
    '6j.me',
    '7y.me',
    '8c.me',
    '8h.me',
    '8w.me',
    '9s.me',
    'iab.me',
    'rcs.me',
    '93.me',
    '0g.me',
    '0r.me',
    '1b.me',
    '1n.me',
    '1p.me',
    '1w.me',
    '2d.me',
    '2s.me',
    '2w.me',
    '3b.me',
    '3q.me',
    '3t.me',
    '3x.me',
    '4b.me',
    '4f.me',
    '4z.me',
    '5f.me',
    '5o.me',
    '5w.me',
    '6t.me',
    '7g.me',
    '7n.me',
    '7p.me',
    '8a.me',
    '8o.me',
    '9a.me',
    'a6.me',
    'e6.me',
    'g8.me',
    'j0.me',
    'l8.me',
    'p8.me',
    's8.me',
    'u8.me',
    'ama.me',
    'gsm.me',
    'wire.me',
    'hook.me',
    'exercise.me'];

