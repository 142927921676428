$("#popup-agree").click(function () {
    $(".privacy-popup").fadeOut("slow");
});

var clickCount = 0;
$('body').on('click', 'form button.btn-block.disableDoubleClick:not(.disabled)', function (e) {
    if (clickCount === 1) {
        $(this).attr("disabled", true);
        return;
    }
    clickCount++;
});